import { SET_REALTIME } from '../constants';

const initialState = {
    realtime: null
};

const laravelEchoReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REALTIME:
            return {
                ...state, 
                realtime: action.payload
            }
        default:
            return state
    }
}

export default laravelEchoReducer;