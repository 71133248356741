import { QUEUE_INIT, QUEUE_UPDATE, QUEUE_CREATE } from '../constants';

const initialState = {
    queue: []
};

const queueReducer = (state = initialState, action) => {
    switch (action.type) {
        case QUEUE_INIT:
            return {
                ...state, 
                queue: []
            }
        case QUEUE_CREATE:
            return {
                ...state, 
                queue: action.payload
            }
        case QUEUE_UPDATE:
            return {
                ...state, 
                queue: state.queue.concat(action.payload)
            }
        default:
            return state
    }
}

export default queueReducer;