import React, { useEffect, useState } from 'react';
import { Button, makeStyles } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import IconClose from '@material-ui/icons/CloseRounded';
import IconSend from '@material-ui/icons/Send';
import Icone from '../../../assets/images/midia-clipboard.svg';
import api from '../../../services/api';

const ClipboardImageComponent = ({ called, onClick}) => {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [modal, setModal] = useState(false);
    const [imageData, setImageData] = useState(null);
    const [progress, setProgress] = useState(false);
    const [frameWidth, setFrameWidth] = useState(300);
    const [frameHeight, setFrameHeight] = useState(200);
    const [overflowX, setOverflowX] = useState('scroll');
    const [overflowY, setOverflowY] = useState('scroll');


    useEffect(() => {
        const init = () => {
            document.onpaste = handlePaste            
        };
        init();
        return () => {
            document.onpaste = null;
        }
    }, [called]);

    function getModalStyle() {
        const top = 50;
        const left = 50;
    
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const handlePaste = (event) => {
        const item = (event.clipboardData || event.originalEvent.clipboardData).items[0];
        if (item.type.indexOf("image") === 0) {
            event.preventDefault();
            const blob = item.getAsFile();
            var reader = new FileReader();
            reader.onload = function(event) {                
                setModal(true);
                const data = event.target.result;
                setImageData(data);

                setTimeout(() => {
                    const img = document.getElementById('imagepreview');
                    let { width, height } = img;
                    if(width < 300) width = 300;
                    if(width > 800) { 
                        width = 800 ;
                        setOverflowX('scroll');
                    } else {
                        setOverflowX('hidden');
                    };

                    if(height < 200) height = 200;
                    if(height > 450) {
                        height = 450;
                        setOverflowY('scroll');                        
                    } else {
                        setOverflowY('hidden');
                    }

                    setFrameWidth(width);
                    setFrameHeight(height);
                }, 50);
            };
            reader.readAsDataURL(blob);
        }
    };

    const send = async () => {
        setProgress(true);
        await api.post(`chat/message/called/${called}/operator/`, { media: imageData });
        setProgress(false);
        setImageData(null);
        setModal(false);
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2>
                <img src={Icone} alt="Screenshot" className={classes.icone} />
                Área de Transferência
            </h2>

            {modal && (
            <div className={classes.center}>
                <div className={classes.frame} style={{width: frameWidth, height: frameHeight, overflowY: overflowY, overflowX: overflowX}}>
                    { imageData && (<img id="imagepreview" src={imageData} alt="Imagem da área de transferência" />)}
                </div>
                <br />


                <Button
                    className={classes.button}
                    onClick={() => {
                        setImageData(null);
                        setModal(false);
                    }}
                    variant="contained"
                    color="default"
                    >
                        <IconClose style={{ marginRight: 7 }} />
                        Cancelar
                </Button>
                &nbsp;&nbsp;

                <Button
                    className={classes.button}
                    onClick={send}
                    disabled={!imageData || progress}
                    variant="contained"
                    color="primary"
                    >
                        <IconSend style={{ color: "#FFFFFF", marginRight: 7 }} />
                        Enviar imagem
                </Button>
                
            </div>
            )}
        </div>
    );

    return (
        <>
            <Modal
                open={modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        maxWidth: 850,
        maxHeight: 600,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },

    icone: {
        width: 36,
        height: 36,
        verticalAlign: 'middle',
        marginRight: 8
    },

    canvas: {},

    frame: {
        backgroundImage: 'url(/assets/images/frame.png)',
        backgroundSize: 'cover',
    },
    
    center: {
        textAlign: 'center',
    },

}));

export default ClipboardImageComponent;