import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TimeAgo from "timeago-react";
import * as timeago from "timeago.js";
import pt_BR from "timeago.js/lib/lang/pt_BR";

import { calledWindowUpdate } from "../../store/actions/called";

import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import api from "../../services/api";
import ChatEditClient from "../ChatEditClient";

const ChatHeader = ({ item }) => {

  timeago.register("pt_BR", pt_BR);

  const classes = useStyles();
  const dispatch = useDispatch();

  const [topSapp, setTopSapp] = useState('')

  useEffect(() => {
    const init = () => {
      handleGetTopSappUrl()
    }
    init()
    return function cleanup() {
      setTopSapp('')
    }
  }, [])

  const handleGetTopSappUrl = async () => {
    try {
      const { data } = await api.get('/parameters/topsapp-url');
      setTopSapp(data.value)
    } catch (error) {
      console.log(error.response.data)
    }
  }

  const handleClose = () => {
    dispatch(calledWindowUpdate(false));
  };


  const handleNewWindow = (url) => {
    window.open(url, "_blank")
  }

  return (
    <div id="contentCenterHeader" className={classes.contentCenterHeader}>
      <div className={classes.contentCenterHeaderLeft}>
        <FontAwesomeIcon
          icon={faUser}
          style={{ color: "#43525A", fontSize: 33, marginRight: 8 }}
        />
        <div>
          <div className={classes.chatHeaderPersonName}>
            {item.conversation.client.name} <ChatEditClient item={item}></ChatEditClient>
          </div>
          <div className={classes.chatHeaderPersonDocument}>
            {item.conversation.client.origin} | {item.conversation.client.uuid}
          </div>
          <div className={classes.linksTopSapp}>
            {
              topSapp.length > 0 &&
              <>
                <button
                  className={classes.chatHeaderClose + " transitionFX"}
                  onClick={() => handleNewWindow(topSapp + '/clientes/' + item.conversation.client.system_id)}
                >Top Sapp</button>
                <button
                  className={classes.chatHeaderClose + " transitionFX"}
                  onClick={() => handleNewWindow(topSapp + '/clientes/' + item.conversation.client.system_id + '/ocorrencias')}
                >Ocorrências</button>
                <button
                  className={classes.chatHeaderClose + " transitionFX"}
                  onClick={() => handleNewWindow(topSapp + '/clientes/' + item.conversation.client.system_id + '/faturas')}
                >Faturas</button>
              </>
            }
          </div>
        </div>
      </div>
      <div className={classes.contentCenterHeaderRight}>
        <button
          className={classes.chatHeaderClose + " transitionFX"}
          onClick={() => handleClose()}
        >FECHAR JANELA</button>
        <div className={classes.chatHeaderDate}>
          <TimeAgo datetime={item.start_at} locale="pt_BR" />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  contentCenterHeader: {
    height: 60,
    padding: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderColor: "#D0D5D9",
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
  },
  contentCenterHeaderLeft: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  contentCenterHeaderRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  chatHeaderPersonName: {
    color: "#43525A",
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: 1,
    marginTop: 2,
  },
  chatHeaderPersonDocument: {
    color: "#43525A",
    fontSize: 10,
  },
  linksTopSapp: {
    marginTop: '1em',
    color: "#43525A",
    fontSize: 10,
  },
  chatHeaderClose: {
    color: "#912424",
    fontSize: 12,
    textAlign: "right",
  },
  chatHeaderDate: {
    color: "#677C88",
    fontSize: 14,
    textAlign: "right",
  },
}));

export default ChatHeader;
