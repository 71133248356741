import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import IconClose from '@material-ui/icons/CloseRounded';
import IconAudio from '@material-ui/icons/MicRounded';
import IconSendAudio from '@material-ui/icons/Send';
import IconStopRecord from '@material-ui/icons/Stop';
import React, { useEffect, useState } from 'react';
import { useReactMediaRecorder } from "react-media-recorder";
import api from '../../../services/api';
import Icone from '../../../assets/images/midia-audio.svg';

const AudioComponent = ({ called, onClick }) => {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [modal, setModal] = useState(false);
    const [recording, setRecording] = useState(false);
    const [audioRecorded, setAudioRecorded] = useState(null);
    const [reader, setReader] = useState(null);
    const [seconds, setSeconds] = useState(0);
    const [progress, setProgress] = useState(false)
    const [submit, setSubmit] = useState(null);

    useEffect(() => {
        const init = async () => {
            if(reader.result) {
                try {
                    setProgress(true);
                    await api.post(`chat/message/called/${called}/operator/`, { media: reader.result });
                    setProgress(false);
                    setModal(false);
                } catch (error) {
                    setProgress(false);
                }
            }
        }
        if(submit) {
            init()
        }
    }, [submit]);

    useEffect(() => {
        let interval = null;
        if(recording) {
            interval = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds + 1);
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [recording]);

    useEffect(() => {
        setReader(new FileReader());
    }, [modal]);

    const {
        clearBlobUrl,
        startRecording,
        stopRecording,
        mediaBlobUrl,
      } = useReactMediaRecorder({ 
            video: false,
            audio: true,
            blobPropertyBag: {
              type: 'audio/mp3'
            },
            onStop: (url, blob) => {
                processAudio(blob);
            }
        });
    
    const openModal = () => {
        onClick();
        clearBlobUrl();
        setReader(null);
        setAudioRecorded(false);
        setModal(true);
    };

    const start = () => {
        setSubmit(false);
        setSeconds(0);
        clearBlobUrl();
        startRecording();
        setAudioRecorded(null);
        setRecording(true);
    };

    const stop = () => {
        stopRecording();
        setRecording(false);
    };

    const processAudio = (blob) => {                
        reader.readAsDataURL(blob);         
        reader.onloadend = () => {
            setAudioRecorded(true);
            return true;
        }
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2>
                <img src={Icone} alt="Áudio" className={classes.icone} />
                Áudio
            </h2>
            {modal && (
            <div>

                {!recording && (
                    <div>
                        <audio className={classes.audio} src={mediaBlobUrl} controls/>
                    </div>
                )}
                
                {recording && (
                    <div className={classes.recording}>
                        <h3>Gravando: {seconds} segundos.</h3>
                    </div>
                )}

                {!recording && (
                <Button
                    className={classes.button}
                    onClick={start}
                    disabled={recording || progress}
                    variant="contained"
                    color="secondary"
                    >
                        <IconAudio style={{ color: "#FFFFFF", marginRight: 7 }} />
                        Gravar
                </Button>
                )}
                
                {recording && (
                <Button
                    className={classes.button}
                    color="primary"
                    onClick={stop}
                    disabled={progress}
                    variant="contained"
                    >
                        <IconStopRecord style={{ color: "#FFFFFF", marginRight: 7 }} />
                        Parar
                </Button>
                )}

                &nbsp;&nbsp;
                <Button
                    className={classes.button}
                    disabled={recording || progress}
                    onClick={() => {
                        setModal(false);
                    }}
                    variant="contained"
                    color="default"
                    >
                        <IconClose style={{ marginRight: 7 }} />
                        Cancelar
                </Button>
                &nbsp;&nbsp;

                {audioRecorded && (
                <Button
                    className={classes.button}
                    color="primary"
                    onClick={() => {setSubmit(true)}}
                    variant="contained"
                    disabled={progress}
                    >
                        <IconSendAudio style={{ color: "#FFFFFF", marginRight: 7 }} />
                        Enviar áudio
                </Button>
                )}

            </div>
            )}
        </div>
    );

    function getModalStyle() {
        const top = 50;
        const left = 50;
    
        return {
          top: `${top}%`,
          left: `${left}%`,
          transform: `translate(-${top}%, -${left}%)`,
        };
      }
    

    return(
        <>
            <Modal
                open={modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>

            <div className={classes.center}>
                <img src={Icone} onClick={() => openModal()} alt="Áudio" /><br />
                ÁUDIO
            </div>
        </>
    );
};

const useStyles = makeStyles((theme) => ({

    paper: {
        position: "absolute",
        width: 500,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },

    icone: {
        width: 36,
        height: 36,
        verticalAlign: 'middle',
        marginRight: 8
    },


    button: {
        marginTop: 15,
    },

    audio: {
        width: "100%"
    },

    recording: {
        height: "58px",
        width: "100%",
        color: "red"
    },

    center: {
        textAlign: 'center'
    }
}));

export default AudioComponent;