import { SYS_INIT, SYS_UPDATE, SYS_CREATE } from '../constants';

const initialState = {
    system: {
        modal: false,
    }
};

const systemReducer = (state = initialState, action) => {
    switch (action.type) {
        case SYS_INIT:
            return {
                ...state, 
                system: initialState
            }
        case SYS_CREATE:
            return {
                ...state, 
                system: action.payload
            }
        case SYS_UPDATE:
            return {
                ...state, 
                system: state.system.concat(action.payload)
            }
        default:
            return state
    }
}

export default systemReducer;