import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { InputBase } from '@material-ui/core';
import api from '../../../services/api';
import Icone from '../../../assets/images/midia-outros.svg';

export default function UploadComponent({ called, onClick }) {

    const classes = useStyles();
    const inputRef = useRef(null);
    const [currentFile, setCurrentFile] = useState(null)

    useEffect(() => {
        const init = async () => {
            if (currentFile) {
                try {
                    await api.post(`chat/message/called/${called}/operator/`, { media: currentFile });
                    setCurrentFile(null);
                } catch (error) {
                    console.log(error);
                }
            }
        }
        init()
    }, [currentFile])

    const handleUpload = (file) => {
        try {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setCurrentFile(reader.result);
            };
        } catch (error) {
            // console.log(error);
        }
    }

    const handleClick = () => {
        inputRef.current.click();
        onClick();
    }

    return (
        <>
            <InputBase inputRef={inputRef} onChange={(e) => handleUpload(e.target.files[0])} type="file" className={classes.inputFileClass} />
            <div className={classes.center}>
                <img src={Icone} onClick={() => handleClick()} alt="Upload" /><br />
                OUTROS
            </div>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    inputFileClass: {
        display: 'none'
    },
    contentCenterFooterLeftMedia: {
        display: 'flex',
        alignItems: 'center'
    },
    center: {
        textAlign: 'center'
    }
}))
