import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo/topsapp03.png';
import api from '../../services/api';

const ForgotPassword = () => {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [processing, setProcessing] = useState(false);

  const handleForgot = async () => {
    try {
      if (email.length > 0) {
        message.length > 0 && setMessage('')
        setProcessing(true);
        const response = await api.post('auth/password/forgot', { email });
        setMessage(response.data.message)
        setEmail('');
      } else {
        setMessage('Digite um email válido.')
      }
    } catch (error) {
      setEmail('');
      if (error.response) {
        setMessage(error.response.data.message)
      }
    }

    setProcessing(false);
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.colLeft}>
          <img src={logo} className={classes.logo} alt="Sistema" />
        </div>
        <div className={classes.colRight}>
          <form id="formLogin" noValidate autoComplete="off">
            {message.length > 0 && <div id="alertMessage" className={classes.messages}>{message}</div>}
            <TextField required
              label="E-mail do operador"
              variant="outlined"
              size="small"
              className={classes.tfUsername}
              onChange={e => setEmail(e.target.value)}
              value={email}
              onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    handleForgot()
                  }
                }}
            />
            <div className={classes.formButton}>
              <Button disabled={processing} onClick={() => handleForgot()} variant="contained" color="primary" className={classes.button}>
                {processing ? 'processando...' : 'Recuperar senha'}
              </Button>
            </div>
            <div id="linksLogin" className={classes.login}>
              <Link to="/login">Login</Link>
            </div>
          </form>
        </div>
      </div>
      <div className={classes.copyright}>&copy; 2021 TopChannel - Todos os Direitos Reservados</div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F5F5F5'
  },
  content: {
    display: 'flex',
    flexDirection: 'row'
  },
  logo: {
    alignItems: 'center',
    maxWidth: 300,
  },
  colLeft: {
    marginRight: 15
  },
  colRight: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 15
  },
  tfUsername: {
    width: 330
  },
  formButton: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    justifyContent: 'space-between'
  },
  button: {
    width: 170
  },
  login: {
    marginTop: 20,
    color: '#43525A',
    fontSize: 14
  },
  messages: {
    marginBottom: 10,
    color: '#43525A',
    fontSize: 14
  },
  copyright: {
    marginTop: 30,
    fontSize: 12,
    color: '#677C88'
  }
}));

export default ForgotPassword;
