import React, { useState } from 'react';

import {
    Danger,
    Success,
    Warning,
    Info
} from './styles'

const Alert = ({ type, data, ...rest }) => {

    const [errors] = useState(data);

    return (
        <>
            {type === 'danger' && <Danger>{errors.message}</Danger>}
            {type === 'success' && <Success>{errors.message}</Success>}
            {type === 'warning' && <Warning>{errors.message}</Warning>}
            {type === 'info' && <Info>{errors.message}</Info>}
        </>
    )
}

export default Alert;