import { QUEUE_INIT, QUEUE_CREATE, QUEUE_UPDATE } from '../constants';

export function queueInit() {
    return { type: QUEUE_INIT }
};

export function queueCreate(value) {
    return { type: QUEUE_CREATE, payload: value }
};

export function queueUpdate(value) {
    return { type: QUEUE_UPDATE, payload: value }
};