import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { useState, useRef, useLayoutEffect, useCallback } from 'react';

const CustomerInfo = ({ customer }) => {

    return (
        <>
            <Table style={{marginBottom: '20px'}}>
                <TableBody>
                    <TableRow>
                        <TableCell style={{width: '33%'}}><strong>Nome</strong><br />{customer.name}</TableCell>
                        <TableCell style={{width: '33%'}}><strong>Email</strong><br />{customer.email}</TableCell>
                        <TableCell style={{width: '33%'}}><strong>UUID</strong><br />{customer.uuid}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{width: '33%'}}><strong>Localização</strong><br />{customer.city} {customer.state}</TableCell>
                        <TableCell style={{width: '33%'}}><strong>Aplicativo</strong><br />{customer.origin}</TableCell>
                        <TableCell style={{width: '33%'}}><strong>Telefone</strong><br />{customer.phone}</TableCell>
                    </TableRow>
                    
                </TableBody>
            </Table>
        </>
    )
};

export default CustomerInfo;