import { combineReducers } from 'redux';

import queueReducer from './queueReducer';
import calledReducer from './calledReducer';
import systemReducer from './systemReducer';
import chatReducer from './chatReducer';
import messageReducer from './messageReducer';
import laravelEchoReducer from './laravelEchoReducer';

const reducers = combineReducers(
    { 
        queueReducer,
        calledReducer,
        systemReducer,
        chatReducer,
        laravelEchoReducer,
        messageReducer
    }
);

export default reducers;