import React, { useEffect, useState } from 'react';
import IconUser from '@material-ui/icons/PersonAdd';
import IconClose from '@material-ui/icons/CloseRounded';
import IconSave from '@material-ui/icons/Done';
import { Button, Modal, makeStyles } from '@material-ui/core';
import { Input, FormControl, Grid, InputLabel, Select, MenuItem } from "@material-ui/core";
import InputMask from "react-input-mask";
import { Alert } from '@material-ui/lab';
import api from '../../../services/api';

const CustomerForm = ({ setCustomer }) => {
    const [modal, setModal] = useState(false);
    const [modalStyle] = useState(getModalStyle);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [phone, setPhone] = useState('');
    const [origin, setOrigin] = useState('');
    const [originSelected, setOriginSelected] = useState('');
    const [origins, setOrigins] = useState([]);
    const [prefix, setPrefix] = useState('');
    const [uuid, setUuid] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [formErrors, setFormErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    
    const classes = useStyles();

    useEffect(() => {
        const loadOrigins = async () => {
            setLoading(true);
            const response = await api.get('chat/channels');
            setOrigins(response.data.data);
            setLoading(false);
        };

        loadOrigins();
    }, []);

    let maskedNumber;

    const openModal = () => {
        setModal(true);
    }

    const cancel = () => {
        setModal(false);
    }

    const updateNumber = (e) => {
        setPhone(e.target.value);
        setNumber(formatNumber(e.target.value));
        setUuid(formatNumber(e.target.value));
    }

    const formatNumber = (str) => {
        let result = "";
        for(let i = 0; i < str.length; i++) {
            if (str[i].match(/[0-9]/)) {
                result += str[i];
            }
        }
        return result.length > 11 ? removeDigit(result) : result;        
    }

    const removeDigit = (str, index = 4) => {
        if (index >= 0 && index < str.length) {
            let p1 = str.slice(0, index);
            let p2 = str.slice(index + 1);
            return p1 + p2;
        } else {
            return str;
        }
    }

    const save = async () => {
        try {            
            setLoading(true);
            setError(false);
            setFormErrors([]);
            setErrorMessage('');
            let data = {
                uuid: prefix + uuid,
                name,
                origin,
                phone: number,
                email
            };

            const response = await api.post('admin/clients', data);
            setCustomer(response.data.data);
            cancel();
            
        } catch(err) {
            const errData = err.response?.data;
            setError(true);
            let msg = errData?.message || 'Ocorreu um erro ao processar a requisição.';
            if(errData?.errors) {
                let fe = [];
                const errors = Object.values(errData.errors);
                errors.map(errmsg => {
                    fe.push(`${errmsg[0]}`);
                });
                setFormErrors(fe);
            }
            setErrorMessage(msg);
        } finally {
            setLoading(false);
        }
    }

    function getModalStyle() {
        const top = 50;
        const left = 50;
    
        return {
          top: `${top}%`,
          left: `${left}%`,
          transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2 className={classes.headerTitle}>
                <IconUser />&nbsp;Novo Contato
            </h2>

            { modal && (
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl className={classes.input}>
                                <InputLabel>Nome completo<span className={classes.required}>*</span></InputLabel>
                                <Input
                                    readOnly={loading}
                                    placeholder=''
                                    label="Cliente"
                                    value={name}
                                    onChange={(e) => { setName(e.target.value)}}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl className={classes.input}>
                                <InputLabel>Email</InputLabel>
                                <Input
                                    type='email'
                                    readOnly={loading}
                                    placeholder=''
                                    label="Email"
                                    value={email}
                                    onChange={(e) => { setEmail(e.target.value)}}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl className={classes.input}>
                                <InputLabel>Telefone<span className={classes.required}>*</span></InputLabel>
                                <InputMask
                                    mask="+55 (99) 99999-9999"
                                    readOnly={loading}
                                    value={maskedNumber}
                                    onChange={updateNumber}
                                >
                                    <Input readOnly={loading} />
                                </InputMask>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={classes.input}>
                                <InputLabel>Aplicativo<span className={classes.required}>*</span></InputLabel>
                                <Select
                                    onChange={(e) => {
                                        setOriginSelected(e.target.value);
                                        setOrigin('api');
                                        setPrefix(e.target.value.prefix);
                                    }}
                                    value={originSelected}
                                    readOnly={loading}
                                >
                                    {origins.map((item) => (
                                        <MenuItem key={item.label} value={item}>{item.label}</MenuItem>
                                    ))}
                                    
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    { error && (
                        <Alert severity='error'>{errorMessage}
                            { formErrors.length > 0 && (
                                <ul>
                                    { formErrors.map((item) => (
                                        <li key={item}>{item}</li>
                                    ))}
                                </ul>
                            )}
                        </Alert>
                    )}

                    <div className={classes.buttonsBottom}>
                        <Button
                            className={classes.button}
                            onClick={cancel}
                            disabled={loading}
                            variant="contained"
                            color="default"
                            >
                                <IconClose style={{ marginRight: 7 }} />
                                Cancelar
                        </Button>

                        <Button
                            disabled={!name || !number || !origin || loading}
                            className={classes.button}
                            onClick={save}
                            variant="contained"
                            color="primary"
                            >
                                <IconSave style={{ marginRight: 7 }} />
                                Salvar
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );


    return (
        <>
            <Modal
                open={modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>

            <Button onClick={() => openModal()}
                className={classes.button}
                variant="contained"
                color="default"
            >
                <IconUser style={{ marginRight: 7 }} />
                Novo Contato
            </Button>
        </>
    )
};

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: 600,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },

    buttonsBottom: {
        marginTop: '30px',
        display: 'flex',
        justifyContent: 'space-between'
    },

    headerTitle: {
        alignItems: 'center',
        display: 'flex'
    },

    input: {
        margin: theme.spacing(1),
        marginBottom: "20px",
        width: "100%",
    },

    required: {
        color: "#ff0000"
    }
}));

export default CustomerForm;