import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import ChatContentLog from "../ChatContentLog";
import ChatContentOperator from "../ChatContentOperator";
import ChatContentOperatorMedia from "../ChatContentOperatorMedia";
import ChatContentClient from "../ChatContentClient";
import ChatContentClientMedia from "../ChatContentClientMedia";

import ScrollToBottom from "react-scroll-to-bottom";

const ChatContent = ({ item }) => {
  const classes = useStyles();

  return (
    <ScrollToBottom
      className={classes.contentCenterChat}
      debug={false}
      mode="bottom"
      initialScrollBehavior="smooth"
    >
      {item.origin && parseInt(item.origin.show_history) === 1 && (
        <div>
          <div>
            {item.origin.messages.map((message) => {
              if (message.is_notification) {
                return <ChatContentLog key={message.id} message={message} />;
              }
              if (message.user_id) {
                if (message.message) {
                  return <ChatContentOperator key={message.id} message={message} user={item.origin.user} />;
                }
                if (message.media) {
                  return (
                    <ChatContentOperatorMedia key={message.id} message={message} user={item.origin.user} />
                  );
                }
              }
              if (message.client_id) {
                if (message.message) {
                  return (
                    <ChatContentClient
                      key={message.id}
                      message={message}
                      client={item.conversation.client}
                    />
                  );
                }
                if (message.media) {
                  return (
                    <ChatContentClientMedia
                      key={message.id}
                      message={message}
                      client={item.conversation.client}
                    />
                  );
                }
              }
            })}
          </div>
          <div className={classes.originSeparator}>
            FIM DO ATENDIMENTO ANTERIOR
          </div>
        </div>
      )}
      
      {item.messages.map((message) => {
        if (message.is_notification) {
          return <ChatContentLog key={message.id} message={message} />;
        }
        if (message.user_id) {
          if (message.message) {
            return <ChatContentOperator key={message.id} message={message} />;
          }
          if (message.media) {
            return (
              <ChatContentOperatorMedia key={message.id} message={message} />
            );
          }
        }
        if (message.client_id) {
          if (message.message) {
            return (
              <ChatContentClient
                key={message.id}
                message={message}
                client={item.conversation.client}
              />
            );
          }
          if (message.media) {
            return (
              <ChatContentClientMedia
                key={message.id}
                message={message}
                client={item.conversation.client}
              />
            );
          }
        }
      })}
    </ScrollToBottom>
  );
};

const useStyles = makeStyles((theme) => ({
  contentCenterChat: {
    overflowY: "auto",
    padding: "15px 25px",
    height: "calc(100vh - 315px)",
    display: "block",
  },

  originSeparator: {
    clear: "both",
    borderBottom: "solid 1px #cecece",
    borderTop: "solid 1px #cecece",
    padding: "10px",
    margin: "10px",
    textAlign: "center",
    backgroundColor: "#ffffdd",
    fontSize: "11px",
  }
}));

export default ChatContent;
