import React, { useEffect, useState } from "react";
import MediaImage from "./MediaImage";
import MediaPdf from "./MediaPdf";
import MediaVideo from "./MediaVideo";
import MediaAudio from "./MediaAudio";
import MediaOther from "./MediaOther";


const ChatContentMedia = ({customStyles, text}) => {

    const [type, setType] = useState(null);
    
    const IMAGE = 1;
    const VIDEO = 2;
    const AUDIO = 3;
    const PDF   = 4;
    const OTHER = 5;

    useEffect(() => {
        if(text.media) {
            if(text.media.includes('image/')) {
                setType(IMAGE);
            } else if(text.media.includes('video/')) {
                setType(VIDEO);
            } else if(text.media.includes('audio/')) {
                setType(AUDIO);
            } else if(text.media.includes('application/pdf')) {
                setType(PDF); 
            } else {
                setType(OTHER);
            }
        }
    }, [text])

    return (
        <>
            { type === IMAGE && <MediaImage customStyles={customStyles} text={text} /> }
            { type === PDF   && <MediaPdf customStyles={customStyles} text={text} /> }
            { type === VIDEO && <MediaVideo customStyles={customStyles} text={text} /> }
            { type === AUDIO && <MediaAudio customStyles={customStyles} text={text} /> }
            { type === OTHER && <MediaOther customStyles={customStyles} text={text} /> }
        </>
    )
};

export default ChatContentMedia;