import React, { useState, useEffect } from 'react';
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
const MediaImage = ({customStyles, text}) => {
    const classes = useStyles();

    const [link, setLink] = useState(null);
    const [modalStyle] = useState(getModalStyle);
    const [showImage, setShowImage] = useState(false);

    useEffect(() => {
        if(text.media.includes('embed:')) {
            const t = text.media.split(';');
            setLink(t[1]);
        } else {
            setLink(text.media);
        }
    }, [text.media]);

    function getModalStyle() {
        const top = 50;
        const left = 50;
    
        return {
          top: `${top}%`,
          left: `${left}%`,
          transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const body = (
        <div
            style={modalStyle}
            className={classes.paper}
            onClick={() => setShowImage(false)}
        >
            <img
            className={classes.paperImg}
            src={link}
            height="100%"
            alt=""
            />
        </div>
    );

    return (
        <>
            <Modal
                open={showImage}
                onClose={(event, reason) => {
                    if(reason === 'backdropClick') {
                        setShowImage(false);
                    }
                }}
            >
                {body}
            </Modal>
            <div className={customStyles.contentChatUserMedia || customStyles.contentChatOpMedia} onClick={() => setShowImage(true)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <img className={classes.image} src={link} alt="thumb" />
                    </Grid>

                </Grid>
            </div>
        </>
    )
};

const useStyles = makeStyles((theme) => ({
    link: {
        cursor: 'pointer',
    },
    image: {
        maxWidth: '250px',
        maxHeight: '250px',
        cursor: 'pointer',
    },
    bodyModalClosed: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },
    paper: {
        position: "absolute",
        width: "auto",
        backgroundColor: "#FFFFFF",
        boxShadow: theme.shadows[5],
        padding: "10px",
    },
    paperImg: {
        maxHeight: "90vh",
        maxWidth: "90vw",
    },
  }));

export default MediaImage;