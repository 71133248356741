import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const ChatContentOperator = ({ message, user }) => {
  const classes = useStyles();
  const [operador, setOperador] = useState(null);
  const [text, setText] = useState(null);

  useEffect(() => {
    const init = async () => {
      setText(message);
      setOperador(operador)
    };
    init();
  }, [message, user]);

  function NewlineText(props) {
    const text = props.text;
    const id = props.id
    return text.split("\n").map((str) => <div key={id}>{str}</div>);
  }

  return (
    <div className={classes.contentChatOpBox}>
      <span className={classes.contentChatOpLog}>
        {user && `${user.name} `}
        {!user && (
          <span>Eu |{" "}</span>
        )}
        {text &&
          new Intl.DateTimeFormat("pt-BR", {
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          }).format(new Date(text.created_at))}
      </span>
      <div className={classes.contentChatOpMessage}>
        <span className={classes.contentChatOpMessageP}>
          {text && <NewlineText id={text.id} key={text.id} text={text.message} />}
        </span>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  contentChatOpBox: {
    textAlign: "right",
    width: "65%",
    float: "right",
    clear: "both",
    marginBottom: "15px",
  },
  contentChatOpLog: {
    fontSize: 12,
    color: "#43525A",
    marginBottom: '25px',
  },
  contentChatOpMessage: {
    backgroundColor: "#F5F5F5",
    padding: "1rem",
    margin: "5px 0 0",
    borderRadius: "7px",
    textAlign: "left",
  },
  contentChatOpMessageP: {
    margin: "0px",
    color: "#43525A",
    fontSize: 15,
  },
}));

export default ChatContentOperator;
