import api from "../../services/api";
import { API_HOST } from "../../configs/constants";

export const updatePasswordService = ({
  email,
  token,
  password,
  confirmPassword,
  role_id,
  userId,
  name,
}) => {
  return api.put(`${API_HOST}admin/users/${userId}/change-password`, {
    password,
    email,
    role_id,
    name,
    password_confirmation: confirmPassword,
  });
};

export const passwordValidation = (password, confirmPassword) => {
  if (password.length >= 6) {
    if (password === confirmPassword) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
