import { SYS_INIT, SYS_CREATE, SYS_UPDATE } from '../constants';

export function sysInit() {
    return { type: SYS_INIT }
};

export function sysCreate(value) {
    return { type: SYS_CREATE, payload: value }
};

export function sysUpdate(value) {
    return { type: SYS_UPDATE, payload: value }
};