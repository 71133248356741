import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const ChatContentClient = ({ message, client }) => {
  const [text, setText] = useState(null);
  const [clientData, setClientData] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    const init = async () => {
      setText(message);
    };
    init();
  }, [message]);

  useEffect(() => {
    const init = async () => {
      setClientData(client);
    };
    init();
  }, [client]);

  function NewlineText(props) {
    const text = props.text;
    const id = props.id;
    return text.split("\n").map((str) => <div key={id}>{str}</div>);
  }

  return (
    <div className={classes.contentChatUserBox}>
      <span className={classes.contentChatUserLog}>
        {text &&
          new Intl.DateTimeFormat("pt-BR", {
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          }).format(new Date(text.created_at))}{" "}
        | {clientData && clientData.name}
      </span>
      <div className={classes.contentChatUserMessage}>
        <span className={classes.contentChatUserMessageP}>
          {text && <NewlineText id={text.id} text={text.message} />}
        </span>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  contentChatUserBox: {
    textAlign: "left",
    width: "65%",
    float: "left",
    clear: "both",
    marginBottom: "15px",
  },
  contentChatUserLog: {
    fontSize: 12,
    color: "#43525A",
    marginBottom: 5,
  },
  contentChatUserMessage: {
    backgroundColor: "#F5F5F5",
    padding: "1rem",
    margin: "5px 0 0",
    borderRadius: "7px",
    textAlign: "left",
  },
  contentChatUserMessageP: {
    margin: 0,
    color: "#43525A",
    fontSize: 15,
  },
}));

export default ChatContentClient;
