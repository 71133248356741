import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ChatContentMedia from "../ChatContentMedia";

const ChatContentOperatorMedia = ({ message }) => {
  const classes = useStyles();

  const [text, setText] = useState(null);

  useEffect(() => {
    const init = async () => {
      setText(message);
    };
    init();
  }, [message]);
  return (
    <>
      <div className={classes.contentChatOpBoxMedia}>
        <span className={classes.contentChatOpLog}>
          Eu |{" "}
          {text &&
            new Intl.DateTimeFormat("pt-BR", {
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            }).format(new Date(text.created_at))}
        </span>

        { text && text.media && (
          <ChatContentMedia customStyles={classes} text={text} />
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  contentChatOpLog: {
    fontSize: 12,
    color: "#43525A",
    marginBottom: 15,
  },
  contentChatOpBoxMedia: {
    textAlign: "right",
    width: "auto",
    maxWidth: "100%",
    float: "right",
    clear: "both",
    marginBottom: "25px",
  },
  contentChatOpMedia: {
    backgroundColor: "#F5F5F5",
    padding: "1rem",
    margin: "5px 0 0",
    borderRadius: "7px",
    textAlign: "right",
  },
}));

export default ChatContentOperatorMedia;