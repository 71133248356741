import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress'
import { isAuthenticated } from "../../services/auth";
import { useHistory } from "react-router-dom";

const AuthLoading = () => {

    let history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        document.title = `checando sessão...`;

        if(!isAuthenticated()){
            history.push("/login");
        }

        history.push("/system");
    });

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <div className={classes.colLeft}>
                    <p>checando sessão...</p>
                </div>
                <div className={classes.colRight}>
                    <div className={classes.progressBar}>
                        <LinearProgress />
                    </div>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    progressBar: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
    },
    logo: {
        width: '100%',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        width: 330,
        display: 'flex',
        flexDirection: 'column'
    },
    colLeft: {
        marginRight: 15
    },
    colRight: {
        
        display: 'flex',
        alignItems: 'center',
    },
    tfUsername: {
        width: 330
    },
    tfPassword: {
        marginRight: 10
    },
    formButton: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10,
        justifyContent: 'space-between'
    },
    button: {
        width: 120
    },
    forgotPassword: {
        marginTop: 20,
        color: '#43525A',
        textDecorationLine: 'underline',
        fontSize: 12
    },
    copyright: {
        marginTop: 40,
        color: '#43525A',
        fontSize: 12
    }
}));

export default AuthLoading;