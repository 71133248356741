import {
  CALLED_INIT,
  CALLED_CREATE,
  CALLED_UPDATE,
  CALLED_UPDATE_ITEM,
  CALLED_WINDOW_UPDATE,
  CALLED_INPUT_TEXT_ADD,
  CALLED_HAS_NEW_MESSAGE,
  CALLED_HAS_NON_NEW_MESSAGE,
} from "../constants";

export function calledInit() {
  return { type: CALLED_INIT };
}

export function calledCreate(value) {
  return { type: CALLED_CREATE, payload: value };
}

export function calledUpdate(value) {
  return { type: CALLED_UPDATE, payload: value };
}

export function calledUpdateItem(value) {
  return { type: CALLED_UPDATE_ITEM, payload: value };
}

export function calledWindowUpdate(value) {
  return { type: CALLED_WINDOW_UPDATE, payload: value };
}

export function calledInputTextAdd(value) {
  return { type: CALLED_INPUT_TEXT_ADD, payload: value };
}
export function calledHasNewMessage(value) {
  return { type: CALLED_HAS_NEW_MESSAGE, payload: value };
}

export function calledHasNonNewMessage(conversationId) {
  return { type: CALLED_HAS_NON_NEW_MESSAGE, payload: conversationId };
}
