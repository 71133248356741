/* LOCALHOST */
export const ADMIN_HOST = process.env.REACT_APP_ADMIN_HOST;
export const API_HOST = process.env.REACT_APP_API_HOST;
export const API_UPLOAD_PATH = process.env.REACT_APP_API_UPLOAD_PATH;
export const LARAVEL_ECHO_HOST = process.env.REACT_APP_LARAVEL_ECHO_HOST;
export const DESKTOP_NOTIFICATION_ENABLED = process.env.REACT_APP_ENABLE_DESKTOP_NOTIFICATION === 'true';

/* STAGING */
// export const API_HOST = "http://192.168.0.100/api/";
// export const LARAVEL_ECHO_HOST = "http://192.168.0.100";
// export const API_UPLOAD_PATH = "http://192.168.0.100/api/";

export const LARAVEL_PREFIX_CHANNELS = 'top_channel_database_';
export const LARAVEL_PREFIX_PRIVATE_CHANNELS = 'top_channel_database_private-';

export const COOKIE_DOMAIN = process.env.NODE_ENV !== 'production' ? "localhost" : null;
export const TOKEN_KEY = "tc-operators";
export const COOKIE_USER = 'tc-user-data'
export const COOKIE_DEPARTMENT = 'tc-department'