import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ChatClientInfo = () => {
  const classes = useStyles();
  const calledReducer = useSelector((state) => state.calledReducer);
  const [item, setItem] = useState(null);

  useEffect(() => {
    const init = async () => {
      if (calledReducer.windowActive !== false) {
        setItem(calledReducer.called[calledReducer.windowActive]);
      } else {
        setItem(null);
      }
    };
    init();
  }, [calledReducer.called, calledReducer.windowActive]);

  return (
    <div className={classes.contentUserInfo}>
      {item && (
        <>
          <div className={classes.contentUserInfoHeader}>
            <FontAwesomeIcon
              icon={faUser}
              style={{ fontSize: 28, marginRight: 7 }}
            />
            <div>
              <div className={classes.infoHeaderPersonName}>
                {item.conversation.client.name}
              </div>
              <div className={classes.infoHeaderPersonDocument}>
                {item.conversation.client.origin}
              </div>
            </div>
          </div>
          <div className={classes.contentUserInfoItens}>
            <div>
              <p className={classes.contentUserInfoP}>
                {item.conversation.client.system_id && (
                  <>
                    <strong style={{ marginRight: 5 }}>SISTEMA:</strong>{" "}
                    {item.conversation.client.system_id}
                  </>
                )}
              </p>
            </div>
            <div>
              <p className={classes.contentUserInfoP}>
                {item.conversation.client.contact && (
                  <>
                    <strong style={{ marginRight: 5 }}>CONTATO:</strong>{" "}
                    {item.conversation.client.contact}
                  </>
                )}
              </p>
            </div>
            <div>
              <p className={classes.contentUserInfoP}>
                {item.conversation.client.phone && (
                  <>
                    <strong style={{ marginRight: 5 }}>CELULAR:</strong>{" "}
                    {item.conversation.client.phone}
                  </>
                )}
              </p>
            </div>
            <div>
              <p className={classes.contentUserInfoP}>
                {item.conversation.client.email && (
                  <>
                    <strong style={{ marginRight: 5 }}>E-MAIL:</strong>{" "}
                    {item.conversation.client.email}
                  </>
                )}
              </p>
            </div>

            { item.origin && (
              <div className={classes.contentObservation}>
                { item.origin.observation && (
                  <span>
                    <strong>OBSERVAÇÃO:</strong>
                    <p className={classes.contentUserInfoP} style={{marginBottom: 10}}>
                      {item.origin.observation}
                    </p>
                  </span>
                )}
                <p className={classes.contentUserInfoP}>
                  <em>
                    <strong>Transferido por:</strong> {item.origin.user.name}<br />
                    <strong>Departamento:</strong> {item.origin.department.name}
                  </em>
                </p>
              </div>
            )}

            <div>
              <p className={classes.contentUserInfoP}>
                <strong style={{ marginRight: 5 }}>ENDEREÇO:</strong>
                {item.conversation.client.street &&
                  item.conversation.client.street}
                {item.conversation.client.number &&
                  ", " + item.conversation.client.number}
                {item.conversation.client.district &&
                  " - " + item.conversation.client.district}
                {item.conversation.client.city &&
                  " - " + item.conversation.client.city}
                {item.conversation.client.state &&
                  "/" + item.conversation.client.state}
                {item.conversation.client.postal_code &&
                  " - CEP: " + item.conversation.client.postal_code}
              </p>
            </div>            
          </div>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  contentUserInfo: {
    marginTop: 18,
  },
  contentUserInfoHeader: {
    color: "#43525A",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  infoHeaderPersonName: {
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: 0.9,
    paddingTop: 5,
  },
  infoHeaderPersonDocument: {
    fontSize: 10,
  },
  contentUserInfoItens: {
    color: "#677C88",
    fontSize: 14,
  },
  contentUserInfoP: {
    marginTop: 5,
    marginBottom: 0,
  },
  contentObservation: {
    marginTop: 15,
    marginBottom: 15,
    borderTop: 'solid 1px #dedede',
    borderBottom: 'solid 1px #dedede',
    paddingTop: 8,
    paddingBottom: 8,
  }
}));

export default ChatClientInfo;
