import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import React, { useCallback, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { COOKIE_DEPARTMENT } from "../../configs/constants";
import api from "../../services/api";
import {
  calledHasNonNewMessage,
  calledInputTextAdd
} from "../../store/actions/called";
import { setTransfer } from "../../store/actions/chat";
import ChatMediaInput from "../ChatMediaInput";

const ChatFooter = ({ item }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const [titleModalClosed, setTitleModalClosed] = useState(
    "Selecione um motivo."
  );
  const [motives, setMotives] = useState([]);
  const [motive, setMotive] = useState("");
  const [observation, setObservation] = useState("");
  const messageReducer = useSelector((state) => state.messageReducer);
  const [modalStyle] = useState(getModalStyle);
  const [ClosedModal, setClosedModal] = useState(false);
  const [cookies] = useCookies([COOKIE_DEPARTMENT]);
  const [loading, setLoading] = useState(false);
  const [closingCalled, setClosingCalled] = useState(false);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    const init = () => {
      setText('');
    }
    setTimeout(init, 50);
  }, []);

  useEffect(() => {
    const init = async () => {
      setText(messageReducer.text);
    };
    init();
  }, [messageReducer.text]);

  useEffect(() => {
    const init = async () => {
      loading
        ? setTitleModalClosed("carregando motivos...")
        : setTitleModalClosed("Selecione um motivo.");
    };
    init();
  }, [loading]);

  const handleLoadMotives = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.get(
        `admin/departments/${cookies[COOKIE_DEPARTMENT].id}/motives`
      );
      setMotives(data.data);
    } catch (error) {
      //
    }
    setLoading(false);
  }, [cookies]);

  useEffect(() => {
    const init = async () => {
      ClosedModal && handleLoadMotives();
    };
    init();
  }, [ClosedModal, handleLoadMotives]);

  const handleErase = () => {
    dispatch(calledInputTextAdd(""));
    setText("");
  };

  const handleTransfer = () => {
    dispatch(
      setTransfer({
        modal: true,
        called: item,
      })
    );
  };

  const handleClosed = async () => {
    if (motive !== "") {
      try {
        setClosingCalled(true);
        await api.post(`chat/end/called/${item.id}/motive/${motive}`, {
          observation,
        });
      } catch (error) {
        setClosingCalled(false);
      }
    }
    setSending(false);
  };

  const handleSendMessage = async () => {
    try {
      if (text.length > 0) {
        setSending(true);
        await api.post(`chat/message/called/${item.id}/operator/`, {
          message: text,
        });
        setText("");
        dispatch(calledHasNonNewMessage(item.id));
      }
    } catch (error) {
      setSending(false);
    }
    setSending(false);
  };

  const handleConfirm = () => {
    confirmAlert({
      title: `Deseja encerrar este atendimento?`,
      closeOnEscape: false,
      closeOnClickOutside: false,
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setClosedModal(true);
            setSending(true);
          },
        },
        {
          label: "Não",
          onClick: () => {
            setSending(false);
          },
        },
      ],
    });
  };

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const setLineBreak = () => {
    setText(text.concat("\n"));
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      {!closingCalled ? (
        <>
          <h2>Encerrar atendimento</h2>
          <p>{titleModalClosed}</p>
          <div className={classes.bodyModalClosed}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Motivo</InputLabel>
              <Select
                disabled={motives.length === 0}
                value={motive}
                onChange={(e) => setMotive(e.target.value)}
                className={classes.selectEmpty}
              >
                {motives.map((motive) => (
                  <MenuItem key={motive.id.toString()} value={motive.id}>
                    {motive.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Observação"
              className={classes.observation}
              onChange={(e) => setObservation(e.target.value)}
              defaultValue={observation}
            />
            <Button
              className={classes.button}
              onClick={() => handleClosed()}
              disabled={motive === null}
              variant="contained"
              color="primary"
            >
              Encerrar atendimento
            </Button>
            <Button
              className={classes.button}
              onClick={() => {
                setClosedModal(false);
                setSending(false);
              }}
              variant="contained"
              color="default"
            >
              Cancelar
            </Button>
          </div>
        </>
      ) : (
        <>
          <h2>Encerrando atendimento...</h2>
          <LinearProgress />
        </>
      )}
    </div>
  );

  return (
    <>
      <Modal
        open={ClosedModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <div className={classes.contentCenterFooter}>
        <div id="chatOptions" className={classes.contentCenterFooterLeft}>
          <ChatMediaInput called={item} />
          <div className={classes.contentCenterFooterLeftSeparator}>&nbsp;</div>
          <button
            className={classes.contentCenterFooterLeftEnd}
            onClick={() => handleConfirm()}
          >
            <CloseIcon style={{ color: "#FFFFFF", fontSize: 30 }} />
            <p className={classes.contentCenterFooterLeftEndP}>
              ENCERRAR
            </p>
          </button>
        </div>
        <div className={classes.contentCenterFooterRight}>
          <div
            id="messageActions"
            className={classes.contentCenterFooterRightRight}
          >
            <button
              className={classes.contentCenterFooterRightRightDelete}
              title="Limpar mensagem"
              onClick={() => handleErase()}
            >
              <FontAwesomeIcon icon={faEraser} style={{ color: "#43525A" }} />
            </button>
            <div className={classes.contentCenterFooterRightRightSeparator}>
              &nbsp;
            </div>
            <button
              onClick={() => handleTransfer()}
              className={classes.contentCenterFooterRightRightTransfer}
              title="Transferir atendimento"
            >
              <SyncAltIcon style={{ color: "#43525A", fontSize: 18 }} />
            </button>
          </div>
          <div
            id="composeMessage"
            className={classes.contentCenterFooterRightCenter}
          >
            <TextField
              inputRef={(textInputRef) => textInputRef && textInputRef.focus()}
              disabled={sending}
              autoFocus
              multiline={true}
              required
              minRows={3}
              maxRows={3}
              aria-label="maximum height"
              placeholder="Digite sua mensagem aqui..."
              value={text}
              onChange={(e) => setText(e.target.value)}
              variant="filled"
              style={{
                width: "100%",
                backgroundColor: "#FFFFFF",
              }}
              onKeyPress={(ev) => {
                if (
                  (ev.ctrlKey && ev.key === "Enter") ||
                  (ev.shiftKey && ev.key === "Enter")
                ) {
                  ev.preventDefault();
                  setLineBreak();
                } else if (ev.key === "Enter") {
                  ev.preventDefault();
                  handleSendMessage();
                }
              }}
            />
          </div>
          <button
            disabled={sending}
            id="sendButton"
            onClick={() => {
              handleSendMessage();
            }}
            className={classes.contentCenterFooterRightLeft}
          >
            {sending ? <CircularProgress size={24} /> : "Enviar"}
          </button>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  contentCenterFooter: {
    backgroundColor: "#43525A",
    color: "#FFFFFF",
    height: 60,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 20,
  },
  selectEmpty: {
    width: "100%",
  },
  observation: {
    marginTop: 15,
  },
  button: {
    marginTop: 15,
  },
  bodyModalClosed: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  contentCenterFooterLeft: {
    display: "flex",
    fontSize: 12,
    textAlign: "left",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  contentCenterFooterLeftMedia: {
    display: "flex",
    alignItems: "center",
  },
  contentCenterFooterLeftSeparator: {
    backgroundColor: "#677C88",
    height: 2,
    marginTop: 3,
    marginBottom: 2,
  },
  contentCenterFooterLeftEnd: {
    display: "flex",
    alignItems: "center",
  },
  contentCenterFooterLeftEndP: {
    margin: 0,
    lineHeight: "1.1",
    paddingBottom: 2,
  },
  contentCenterFooterRight: {
    flex: 1,
    marginLeft: 20,
    display: "flex",
    flexDirection: "row",
  },
  contentCenterFooterRightRight: {
    backgroundColor: "#D0D5D9",
    height: 60,
  },
  contentCenterFooterRightRightDelete: {
    display: "flex",
    alignItems: "center",
    padding: "7px 8px 4px 8px",
    fontSize: 15,
  },
  contentCenterFooterRightRightSeparator: {
    backgroundColor: "#677C88",
    height: 2,
    marginTop: 2,
    marginBottom: 2,
  },
  contentCenterFooterRightRightTransfer: {
    display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    fontSize: 18,
  },
  contentCenterFooterRightCenter: {
    flex: 1,
  },
  contentCenterFooterRightLeft: {
    backgroundColor: "#D0D5D9",
    border: 0,
    cursor: "pointer",
    color: "#43525A",
    textTransform: "uppercase",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 12,
    width: 100,
    height: 60,
  },
}));

export default ChatFooter;
