import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ChatContentMedia from "../ChatContentMedia";

const ChatContentClientMedia = ({ message, client }) => {
  const classes = useStyles();

  const [text, setText] = useState(null);
  const [clientData, setClientData] = useState(null);

  useEffect(() => {
    const init = async () => {
      setText(message);
    };
    init();
  }, [message]);

  useEffect(() => {
    const init = async () => {
      setClientData(client);
    };
    init();
  }, [client]);

  return (
    <>
      <div className={classes.contentChatUserBoxMedia}>
        <span className={classes.contentChatOpLog}>
          {text &&
            new Intl.DateTimeFormat("pt-BR", {
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            }).format(new Date(text.created_at))}{" "}
          | {clientData && clientData.name}
        </span>
        { text && text.media && (
          <ChatContentMedia customStyles={classes} text={text} />
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  contentChatOpLog: {
    fontSize: 12,
    color: "#43525A",
    marginBottom: 15,
  },
  contentChatUserBoxMedia: {
    textAlign: "left",
    width: "auto",
    maxWidth: "100%",
    float: "left",
    clear: "both",
    marginBottom: "25px",
  },
  contentChatUserMedia: {
    backgroundColor: "#F5F5F5",
    padding: "1rem",
    margin: "5px 0 0",
    borderRadius: "7px",
    textAlign: "right",
  },
}));

export default ChatContentClientMedia;
