export const ADD_USER = "ADD_USER";

export const ADD_DEPARTMENT = "ADD_DEPARTMENT";

/** QUEUE CONSTANTS  */
export const QUEUE_INIT = "QUEUE_INIT";
export const QUEUE_CREATE = "QUEUE_CREATE";
export const QUEUE_UPDATE = "QUEUE_UPDATE";

/** CALLED CONSTANTS  */
export const CALLED_INIT = "CALLED_INIT";
export const CALLED_CREATE = "CALLED_CREATE";
export const CALLED_UPDATE = "CALLED_UPDATE";
export const CALLED_UPDATE_ITEM = "CALLED_UPDATE_ITEM";
export const CALLED_WINDOW_UPDATE = "CALLED_WINDOW_UPDATE";
export const CALLED_INPUT_TEXT_ADD = "CALLED_INPUT_TEXT_ADD";
export const CALLED_HAS_NEW_MESSAGE = "CALLED_HAS_NEW_MESSAGE";
export const CALLED_HAS_NON_NEW_MESSAGE = "CALLED_HAS_NON_NEW_MESSAGE";

/** SYS CONSTANTS  */
export const SYS_INIT = "SYS_INIT";
export const SYS_CREATE = "SYS_CREATE";
export const SYS_UPDATE = "SYS_UPDATE";

/** CHAT CONSTANTS  */
export const TRANSFER_OPEN_MODAL = "TRANSFER_OPEN_MODAL";
export const SET_TRANSFER = "SET_TRANSFER";
export const SET_REALTIME = "SET_REALTIME";
export const SET_MESSAGE = "SET_MESSAGE";
