import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import logo from '../../assets/logo/topsapp03.png';
import queryString from 'query-string';
import api from '../../services/api';

const ForgotChangePassword = (props) => {
  
  const history = useHistory();
  const classes = useStyles();

  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [message, setMessage] = useState('');
  const [processing, setProcessing] = useState(false);
  const [params, setParams] = useState({});

  useEffect(() => {
    document.title = `Nova senha`;
  });

  useEffect(() => {
    const init = async () => {
      try {
        if (params.e.length) {
          setProcessing(true);
          setMessage('Validando token...')
          const response = await api.post('auth/password/token/check', { email: params.e, token: params.t });
          if (response.data.status) {
            setProcessing(false);
            setMessage('')
          } else {
            setMessage('Falha em validar o token e email')
          }
        }
      } catch (error) {
        if (error.response) {
          setMessage(error.response.data.message)
        }
      }
    }
    init();
  }, [params]);

  useEffect(() => {
    let params = queryString.parse(props.location.search)
    setParams(params)
  }, [props])

  const validaData = () => {
    if (!password.length || !passwordConfirm.length) {
      setMessage('Preecha todos os campos')
      return false
    }
    if (password !== passwordConfirm) {
      setMessage('Senhas digitadas não conferem')
      setPassword('')
      setPasswordConfirm('')
      return false
    }
    return true
  }

  const handlePasswordChange = async () => {
    try {
      if (validaData()) {
        setProcessing(true);
        setMessage('processando...')
        const response = await api.post('auth/password/forgot/change', {
          email: params.e,
          token: params.t,
          password,
          password_confirmation: passwordConfirm
        });
        if (response.data.status) {
          setMessage(response.data.message + " AGUARDE...")
          setTimeout(() => history.push("/"), 2000)
        } else {
          setProcessing(false);
          setMessage('Falha, tente novamente')
        }
      }
    } catch (error) {
      if (error.response) {
        setProcessing(false);
        setMessage(error.response.data.message)
      }
    }
  }


  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.colLeft}>
          <img src={logo} className={classes.logo} alt="Sistema" />
        </div>
        <div className={classes.colRight}>
          {processing ? (<h4>{message}</h4>) :
            (<form id="formLogin" noValidate autoComplete="off">
              {params.e && (<h4>Trocar a senha do email {params.e}</h4>)}

              <h5>{message}</h5>

              <TextField
                type="password"
                onChange={e => setPassword(e.target.value)}
                required
                label="NOVA SENHA:"
                variant="outlined"
                size="small"
                className={classes.tfUsername}
                value={password}
              />

              <TextField
                type="password"
                onChange={e => setPasswordConfirm(e.target.value)}
                required
                label="CONFIRME:"
                variant="outlined"
                size="small"
                value={passwordConfirm}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    handlePasswordChange()
                  }
                }}
              />

              <div className={classes.formButton}>
                <Button variant="contained" color="primary" onClick={() => handlePasswordChange()} className={classes.button}>ALTERAR SENHA</Button>
              </div>
              <div id="linksLogin" className={classes.login}>
                <Link to="/login">Login</Link>
              </div>
            </form>)}
        </div>
      </div>
      <div className={classes.copyright}>&copy; 2021 TopChannel - Todos os Direitos Reservados</div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F5F5F5'
  },
  content: {
    display: 'flex',
    flexDirection: 'row'
  },
  logo: {
    alignItems: 'center',
    maxWidth: 300,
  },
  colLeft: {
    marginRight: 15
  },
  colRight: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 15
  },
  tfUsername: {
    marginRight: 10
  },
  formButton: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    justifyContent: 'space-between'
  },
  button: {
    width: 170
  },
  login: {
    marginTop: 20,
    color: '#43525A',
    fontSize: 14
  },
  copyright: {
    marginTop: 30,
    fontSize: 12,
    color: '#677C88'
  }
}));


export default ForgotChangePassword;
