import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux'

import { login } from "../../services/auth";
import { COOKIE_USER, COOKIE_DOMAIN, COOKIE_DEPARTMENT } from "../../configs/constants";
import { queueInit } from '../../store/actions/queue'
import { calledInit } from '../../store/actions/called'

import Alert from '../../components/Alert';
import api from '../../services/api';

import logo from '../../assets/logo/topsapp03.png';

const Login = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_USER, COOKIE_DEPARTMENT]);

  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState({});
  const [authData, setAuthData] = useState({});

  useEffect(() => {
    document.title = `Login`;
    dispatch(queueInit())
    dispatch(calledInit())
    removeCookie(COOKIE_USER)
    removeCookie(COOKIE_DEPARTMENT)
  }, []);

  useEffect(() => {
    const init = async () => {
      if (authData.access_token && authData.user) {
        setCookie(COOKIE_USER, authData.user);
        setCookie(COOKIE_DEPARTMENT, authData.user.departments[0]);
        handleLogin(authData.access_token);
      }
    }
    init();
  }, [authData]);

  const handleLogin = token => {
    if (token) {
      login(token);
      return history.push("/");
    }
  }

  const validation = () => {
    if (!email.length || !password.length) {
      setErrors({ message: 'Todos os campos são obrigatórios.' })
      return false;
    }
    return true;
  }

  const handleSignUp = async () => {
    try {
      if (validation()) {
        errors && setErrors({})
        setProcessing(true);
        const { data } = await api.post('auth/login', { email, password });
        setAuthData(data.data);
      }
    } catch (error) {
      setPassword('');
      if (error.response) {
        setErrors(error.response.data)
      }
    }
    setProcessing(false);
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.colLeft}>
          <img src={logo} alt="Sistema" className={classes.logo} />
        </div>
        <div className={classes.colRight}>
          {Object.keys(errors).length !== 0 && <Alert type="warning" data={errors} />}
          <form id="formLogin" noValidate autoComplete="off">
            <TextField
              required
              label="E-MAIL:"
              variant="outlined"
              size="small"
              className={classes.tfUsername}
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
            <div className={classes.formButton}>
              <TextField
                type="password"
                required
                label="SENHA:"
                variant="outlined"
                size="small"
                className={classes.tfPassword}
                onChange={e => setPassword(e.target.value)}
                value={password}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    handleSignUp()
                  }
                }}
              />
              <Button
                disabled={processing}
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => handleSignUp()}>
                {processing ? 'processando...' : 'Entrar'}
              </Button>
            </div>
            <div id="linksLogin" className={classes.forgotPassword}>
              <Link to="/forgot-password">Recuperar senha</Link>
            </div>
          </form>
        </div>
      </div>
      <div className={classes.copyright}>&copy; 2021 TopChannel - Todos os Direitos Reservados</div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F5F5F5'
  },
  content: {
    display: 'flex',
    flexDirection: 'row'
  },
  logo: {
    alignItems: 'center',
    maxWidth: 300,
  },
  colLeft: {
    flexDirection: 'row',
    justifyItems: 'center',
    marginBottom: 20
  },
  colRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 15
  },
  tfUsername: {
    width: 330
  },
  tfPassword: {
    marginRight: 10,
    width: 200
  },
  formButton: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    justifyContent: 'space-between'
  },
  button: {
    width: 120
  },
  forgotPassword: {
    marginTop: 15,
    color: '#43525A',
    fontSize: 14
  },
  copyright: {
    marginTop: 30,
    fontSize: 12,
    color: '#677C88'
  }
}));

export default Login;
