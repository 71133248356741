import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { setTransfer } from "../../store/actions/chat";
import Button from "@material-ui/core/Button";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { useCookies } from "react-cookie";
import { Input, Checkbox, FormGroup, FormControlLabel } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { COOKIE_DEPARTMENT } from "../../configs/constants";

import api from "../../services/api";

const ChatTransfer = ({ item }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const transfer = useSelector((state) => state.chatReducer.transfer);
  const [modalStyle] = useState(getModalStyle());
  const [cookies] = useCookies([COOKIE_DEPARTMENT]);
  const [obs, setObs] = useState("");
  const obsRef = useRef(null);
  const [step, setStep] = useState(1);
  const [destination, setDestination] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState(null);
  const [motives, setMotives] = useState([]);
  const [motive, setMotive] = useState(null);
  const [history, setHistory] = useState(true);
  const [operators, setOperators] = useState([]);
  const [operator, setOperator] = useState(null);
  const [loading, setLoading] = useState(false);

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const generateUrl = () => {
    let base = `chat/transfer/${transfer.called.id}/motive/${motive}/destination/${department}`;
    return destination === "dep" ? base : base + `/${operator}`;
  };

  const handleHistory = (e) => {
    setHistory(e);
  }

  const handleTransfer = async () => {
    try {
      setStep("transferring");
      const { data } = await api.post(generateUrl(), { observation: obs, show_history: (history ? '1' : '0') });
      handleCancel();
    } catch (error) {
      //
    }
    setStep(1);
  };

  const handleLoadDepartments = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`admin/departments?per_page=100`);
      setDepartments(data.data.data);
    } catch (error) {
      //
    }
    setLoading(false);
  };

  const handleLoadMotives = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.get(
        `admin/departments/${cookies[COOKIE_DEPARTMENT].id}/motives`
      );
      setMotives(data.data);
    } catch (error) {
      //
    }
    setLoading(false);
  }, [cookies]);

  const handleLoadOperators = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.get(
        //`admin/departments/${department}/users/online`
        `admin/departments/${department}/users`
      );
      setOperators(data.data);
    } catch (error) {
      //
    }
    setLoading(false);
  }, [department]);

  const handleCancel = () => {
    dispatch(
      setTransfer({
        modal: false,
        called: null,
      })
    );
    setStep(1);
    setDestination(null);
    setMotive(null);
    setDepartment(null);
    setObs("");
  };

  useEffect(() => {
    const init = async () => {
      if (destination === "dep") {
        handleLoadDepartments();
        handleLoadMotives();
        setStep(2);
      }
      if (destination === "op") {
        handleLoadDepartments();
        handleLoadMotives();
        setStep(3);
      }
    };
    init();
  }, [destination, handleLoadMotives]);

  useEffect(() => {
    const init = async () => {
      if (destination === "op") {
        handleLoadOperators();
      }
    };
    init();
  }, [department, destination, handleLoadOperators]);

  const SelectDepartment = () => {
    return (
      <>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">
            {loading ? "carregando..." : "Selecione um departamento"}
          </InputLabel>
          <Select
            disabled={departments.length === 0}
            onChange={(e) => setDepartment(e.target.value)}
            value={department}
          >
            {departments.map((item) => (
              <MenuItem key={item.id.toString()} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  };

  const SelectOperator = () => {
    return (
      <>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">
            {loading ? "carregando..." : "Selecione um operador"}
          </InputLabel>
          <Select
            disabled={operators.length === 0}
            onChange={(e) => setOperator(e.target.value)}
            value={operator}
          >
            {operators.map((item) => (
              <MenuItem key={item.id.toString()} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  };

  const SelectMotive = () => {
    return (
      <>
        <FormControl className={classes.formControl}>
          <InputLabel>
            {loading ? "carregando..." : "Selecione um motivo"}
          </InputLabel>
          <Select
            disabled={motives.length === 0}
            onChange={(e) => setMotive(e.target.value)}
            value={motive}
          >
            {motives.map((item) => (
              <MenuItem key={item.id.toString()} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  };

  const stepTransferring = (
    <>
      <h2>Transferindo atendimento...</h2>
      <LinearProgress />
    </>
  );

  const step1 = (
    <div className={classes.step1}>
      <p>Para onde deseja transferir este chamado?</p>
      <div className={classes.step1Buttons}>
        <Button
          className={classes.buttonStep1}
          onClick={() => setDestination("dep")}
          variant="outlined"
          color="primary"
        >
          Para um departamento
        </Button>
        <Button
          className={classes.buttonStep1}
          onClick={() => setDestination("op")}
          variant="outlined"
          color="secondary"
        >
          Para um operador
        </Button>
      </div>
    </div>
  );

  const step2 = (
    <div className={classes.step1}>
      {destination === "dep" && <SelectDepartment />}
      {department !== null && <SelectMotive />}
    </div>
  );

  const step3 = (
    <div className={classes.step1}>
      {destination === "op" && <SelectDepartment />}
      {department !== null && <SelectOperator />}
      {operator !== null && <SelectMotive />}
    </div>
  );

  const body = (
    <div style={modalStyle} className={classes.paper}>
      {step !== "transferring" && (
        <h2>
          <SyncAltIcon style={{ color: "#43525A", fontSize: 30 }} /> Transferir
          atendimento
        </h2>
      )}
      {step === 1 && step1}
      {step === 2 && step2}
      {step === 3 && step3}
      {(step === 2 || step === 3) && (
        <div>
          <FormControl className={classes.formControl2}>
            <Input
              placeholder="observação"
              className={classes.observation}
              label="Observação"
              onChange={(evt) => setObs(evt.target.value)}
              value={obs}
              ref={obsRef}
            />
          </FormControl>
          <FormControl>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={history} onClick={(e) => { handleHistory(e.target.checked) }}></Checkbox>
                }
                label="Permitir acesso ao histórico deste atendimento."
              />
            </FormGroup>
          </FormControl>
        </div>
      )}
      {step === "transferring" && stepTransferring}
      {step !== "transferring" && (
        <div>          
          <p className={classes.bodyModalClosed}>
            <Button
              disabled={motive === null}
              className={classes.button}
              onClick={() => handleTransfer()}
              variant="outlined"
              color="primary"
            >
              <SyncAltIcon style={{ color: "#43525A", fontSize: 18 }} />{" "}
              Transferir
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleCancel()}
              variant="outlined"
              color="default"
            >
              Cancelar
            </Button>
          </p>
        </div>
      )}
    </div>
  );

  return <Modal open={transfer.modal}>{body}</Modal>;
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControl2: {
    margin: theme.spacing(1),
    width: "100%",
  },
  bodyModalClosed: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  step1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  step1Buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonStep1: {
    width: "45%",
    marginTop: 10,
    height: 100,
  },
  button: {
    marginTop: 15,
  },
}));

export default ChatTransfer;
