import { SET_TRANSFER } from '../constants';

const initialState = {
    transfer: {
        modal: false,
        called: null
    }
};

const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TRANSFER:
            return {
                ...state, 
                transfer: action.payload
            }
        default:
            return state
    }
}

export default chatReducer;