import React, { useEffect, useState } from "react";

const DesktopNotification = ({ message }) => {

    const [ready, setReady] = useState(false);
    useEffect(() => {
        const init = () => {            
            if (!("Notification" in window)) {
                console.log("Este navegador não suporta notificações.");
            } else {
                setReady(false);
                Notification.requestPermission();
            }
        };

        init();
    });

    useEffect(() => {
        const showMessage = () => {
            const arr = window.location.href.split("/");
            const baseUrl = arr[0] + "//" + arr[2];

            const options = {
                body: message,
                icon: `${baseUrl}/assets/images/notification.png`,
                dir: "ltr"
            };
            return new Notification("TopChannel", options);
        };

        if(message) {
            showMessage();
        }
    }, [message]);

    return (
        <></>
    );
};

export default DesktopNotification;