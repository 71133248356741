import {
  CALLED_INIT,
  CALLED_UPDATE,
  CALLED_UPDATE_ITEM,
  CALLED_CREATE,
  CALLED_WINDOW_UPDATE,
  CALLED_INPUT_TEXT_ADD,
  CALLED_HAS_NEW_MESSAGE,
  CALLED_HAS_NON_NEW_MESSAGE,
} from "../constants";

const initialState = {
  windowActive: false,
  inputText: "",
  called: [],
};

const calledReducer = (state = initialState, action) => {
  switch (action.type) {
    case CALLED_INIT:
      return {
        ...state,
        windowActive: false,
        called: [],
      };
    case CALLED_CREATE:
      return {
        ...state,
        windowActive: false,
        called: action.payload,
      };
    case CALLED_UPDATE:
      return {
        ...state,
        called: state.called.concat(action.payload),
      };
    case CALLED_UPDATE_ITEM:
      return {
        ...state,
        called: state.called.map((item, index) => {
          const updatedItem =
            item.id === action.payload.id ? action.payload : item;

          if (item.id === action.payload.id) {
            updatedItem.hasNewMessages = true;
          }

          if (index === state.windowActive) updatedItem.hasNewMessages = false;

          return updatedItem;
        }),
      };
    case CALLED_WINDOW_UPDATE:
      return {
        ...state,
        windowActive: action.payload,
        called: state.called.map((item) => {
          if (item.id === action.payload) {
            item.hasNewMessages = false;
            return item;
          }
          return item;
        }),
      };
    case CALLED_INPUT_TEXT_ADD:
      return {
        ...state,
        inputText: action.payload,
      };
    case CALLED_HAS_NON_NEW_MESSAGE:
      return {
        ...state,
        called: state.called.map((item) => {
          if (item.id === action.payload) {
            item.hasNewMessages = false;
            return item;
          }
          return item;
        }),
      };
    case CALLED_HAS_NEW_MESSAGE:
      return {
        ...state,
        called: state.called.map((item) => {
          if (item.id === action.payload) {
            item.hasNewMessages = true;
            return item;
          }
          return item;
        }),
      };
    default:
      return state;
  }
};

export default calledReducer;
