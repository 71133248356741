import React, { useEffect, useState } from 'react';
import { Button, makeStyles } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import IconClose from '@material-ui/icons/CloseRounded';
import IconSend from '@material-ui/icons/Send';
import Icone from '../../../assets/images/midia-screen-capture.svg';
import api from '../../../services/api';

const ScreenCaptureComponent = ({ called, onClick }) => {
    const classes = useStyles();
    const [modal, setModal] = useState(false);
    const [captured, setCaptured] = useState(false);
    const [modalStyle] = useState(getModalStyle);
    const [progress, setProgress] = useState(false);
    const [imageData, setImageData] = useState(null);

    useEffect(() => {
        setImageData(null);
    }, [modal]);

    function getModalStyle() {
        const top = 50;
        const left = 50;
    
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const openModal = () => {
        setModal(true);
        startCapture();
        onClick();
    };

    const startCapture = async () => {
        const enabled = navigator.mediaDevices.getDisplayMedia;
        if(!enabled) return false;

        const displayMediaOptions = {
            video: { mediaSource: 'screen'  }
        };
      
        try {
            const stream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
            getBlobFromMediaStream(stream).then(blob => {
                setImageData(blob);
                setCaptured(true);
            }).catch(error => {
                console.log(`Não foi possível capturar tela. ${error}`);
                setModal(false);
            });
        } catch(err) {
            console.error("Erro: " + err);
            setModal(false);
        }
        
    };

    const getBlobFromMediaStream = async (stream) => {
        if ('ImageCapture' in window) {
            const videoTrack = stream.getVideoTracks()[0];
            const imageCapture = new ImageCapture(videoTrack);            
            const bitmap = await imageCapture.grabFrame();
            videoTrack.stop();
            const canvas = document.getElementById('screenshot');
            canvas.width = bitmap.width;
            canvas.height = bitmap.height;
            const context = canvas.getContext('2d');
            context.drawImage(bitmap, 0, 0, canvas.width, canvas.height);
            return canvas.toDataURL();
        } else {    
            const video = document.createElement('video');
            const canvas = document.getElementById('screenshot');
            const context = canvas.getContext('2d');
        
            video.srcObject = stream;
        
            return new Promise((resolve, reject) => {
                video.addEventListener('loadeddata', async () => {
                    const { videoWidth, videoHeight } = video;
                    canvas.width = videoWidth;
                    canvas.height = videoHeight;            
                    try {
                        await video.play();
                        context.drawImage(video, 0, 0, videoWidth, videoHeight);
                        resolve(canvas.toDataURL());
                    } catch (error) {
                        reject(error);
                    }
                });
            });
        }        
    }

    const send = async () => {
        setProgress(true);
        await api.post(`chat/message/called/${called}/operator/`, { media: imageData });
        setProgress(false);
        setModal(false);
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2>
                <img src={Icone} alt="Screenshot" className={classes.icone} />
                Captura de tela
            </h2>
            {modal && (
            <div className={classes.center}>
                <div className={classes.frame}>
                    <canvas id="screenshot" className={classes.canvas}></canvas>
                </div>
                <br />


                <Button
                    className={classes.button}
                    onClick={() => {
                        setModal(false);
                    }}
                    variant="contained"
                    color="default"
                    >
                        <IconClose style={{ marginRight: 7 }} />
                        Cancelar
                </Button>
                &nbsp;&nbsp;

                <Button
                    className={classes.button}
                    onClick={send}
                    disabled={!captured || progress}
                    variant="contained"
                    color="primary"
                    >
                        <IconSend style={{ color: "#FFFFFF", marginRight: 7 }} />
                        Enviar imagem
                </Button>
                
            </div>
            )}
        </div>
    );

    return (
        <>
            <Modal
                open={modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>

            <div className={classes.center}>
                <img src={Icone} onClick={() => openModal()} alt="Capturar tela" /><br />
                TELA
            </div>
        </>
    )
};

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: 850,
        minHeight: 500,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },

    icone: {
        width: 36,
        height: 36,
        verticalAlign: 'middle',
        marginRight: 8
    },

    canvas: {},

    frame: {
        width: 850,
        height: 500,
        backgroundImage: 'url(/assets/images/frame.png)',
        backgroundSize: 'cover',
        overflow: 'scroll'
    },
    
    center: {
        textAlign: 'center'
    }
}));

export default ScreenCaptureComponent;