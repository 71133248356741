import React, { useState, useRef, useLayoutEffect, useCallback, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Input, FormControl, Grid, InputLabel, Select, MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconSearch from '@material-ui/icons/Search';
import { makeStyles } from "@material-ui/core";
import api from '../../../services/api';

const CustomerGridComponent = ({ setCustomer }) => {

    const tableEl = useRef();
    const keywordRef = useRef(null);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(null);
    const [nextPage, setNextPage] = useState(null);
    const [distanceBottom, setDistanceBottom] = useState(0);
    const [hasMore] = useState(true);
    const classes = useStyles();
    const [keyword, setKeyword] = useState("");
    const [origins, setOrigins] = useState([]);
    const [origin, setOrigin] = useState('all');

    const getUrl = () => {
        return currentPage ? nextPage : `admin/clients?search=${keyword || ''}&origin=${origin}`;
    }

    useEffect(() => {
        if(keywordRef && keywordRef.current) keywordRef.current.focus();
        const loadOrigins = async () => {
            setLoading(true);
            const response = await api.get('admin/origins');
            setOrigins(response.data);
            setLoading(false);
        };

        loadOrigins();
    }, []);

    const handleChange = (evt) => {
        setKeyword(evt.target.value);
        setCustomer(null);
        setRows([]);
        setCurrentPage(null);
        setNextPage(null);
    };

    const handleChangeOrigin = (evt) => {
        setOrigin(evt.target.value);
        setCustomer(null);
        setRows([]);
        setCurrentPage(null);
        setNextPage(null);
    };
    
    const handleSubmit = useCallback((evt) => {
        if(evt) {
            if(evt.key !== 'Enter' || keyword.length < 3) return false;
        } else {
            if(keyword.length < 3) return false;
        }
        
        setRows([]);
        setCurrentPage(null);
        setNextPage(null);
        setCurrentPage(null);
        setNextPage(null);
        loadMore();
    }, [keyword, rows]);

    const loadMore = useCallback(() => {
        const loadItems = async () => {
            try {
                const response = await api.get(getUrl());
                const data = response.data.data.data;
                setNextPage(response.data.data.next_page_url);
                setCurrentPage(response.data.data.current_page);
                let newRows = Object.assign(rows, {});
                data.map((item) => {
                    newRows.push(item);
                });
                setRows(newRows);
            } catch(err) {
                setRows(null);
                setNextPage(null);
                setCurrentPage(null);
            } finally {
                setLoading(false);
            }
        }

        if((!currentPage || nextPage) && keyword.length >= 3) {
            setLoading(true);
            loadItems();
        }
    }, [rows, keyword, nextPage]);
    
    const scrollListener = useCallback(() => {
        let bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight
        if (!distanceBottom) {
            setDistanceBottom(Math.round(bottom * 0.2))
        }
        if (tableEl.current.scrollTop > bottom - distanceBottom && hasMore && !loading) {
            loadMore()
        }
      }, [hasMore, loadMore, loading, distanceBottom]);

      useLayoutEffect(() => {
        const tableRef = tableEl.current
        tableRef.addEventListener('scroll', scrollListener)
        return () => {
            tableRef.removeEventListener('scroll', scrollListener)
        }
    }, [scrollListener]);

    const selectCustomer = (customer) => {
        setCustomer(customer);
    };

    const highlight = (text) => {
        if(!text) return '';
        let regex = new RegExp(keyword, 'gi');
        return text.replace(regex, "<span style='background-color: yellow'>" + keyword + "</span>");
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={7}>
                    <FormControl className={classes.searchInput}>
                        <InputLabel>Nome, email, telefone ou UUID do cliente</InputLabel>
                        <Input
                            inputRef={keywordRef}
                            placeholder=""
                            label="Cliente"
                            onKeyPress={handleSubmit}
                            onChange={handleChange}
                            readOnly={loading}
                            value={keyword}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl className={classes.searchInput}>
                        <InputLabel>
                        {loading ? "carregando..." : "Aplicativo"}
                        </InputLabel>
                        <Select
                            disabled={loading}
                            onChange={handleChangeOrigin}
                            value={origin}
                        >
                            <MenuItem key="all" value="all">Todos</MenuItem>
                            {origins.map((item) => (
                                <MenuItem key={item.origin} value={item.origin}>
                                    {item.origin}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={2}>
                    <br />
                    <Button
                        onClick={() => {
                            handleSubmit();
                        }}
                        disabled={keyword.length < 3 || loading}
                        variant="contained"
                        color="primary"
                        >
                            <IconSearch style={{ marginRight: 7 }} />
                            Procurar
                    </Button>
                </Grid>
            </Grid>

        
            <TableContainer style={{ width: '100%', margin: 'auto', height: '300px' }} ref={tableEl}>
            {loading && <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} />}
            <Table stickyHeader>
                <TableHead>
                <TableRow>
                    <TableCell style={{width: '35%'}}>Cliente</TableCell>
                    <TableCell style={{width: '35%'}}>Email</TableCell>
                    <TableCell style={{width: '15%'}}>Aplicativo</TableCell>
                    <TableCell style={{width: '15%'}}>Telefone</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((item) => (
                    <TableRow key={item.id} hover={true} className={classes.item} onClick={() => { selectCustomer(item)}}>
                    <TableCell className={classes.uppercase}>
                        <div dangerouslySetInnerHTML={{__html: highlight(item.name)}} />
                        <div className={classes.uuid}>UUID: <span dangerouslySetInnerHTML={{__html: highlight(item.uuid)}}></span></div>
                    </TableCell>
                    <TableCell className={classes.uppercase} dangerouslySetInnerHTML={{__html: highlight(item.email)}}></TableCell>
                    <TableCell className={classes.uppercase}>{item.origin}</TableCell>
                    <TableCell className={classes.uppercase} dangerouslySetInnerHTML={{__html: highlight(item.phone)}}></TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        </>
    )
};

const useStyles = makeStyles((theme) => ({
    searchInput: {
        margin: theme.spacing(1),
        marginBottom: "20px",
        width: "100%",
    },

    button: {
        marginTop: 15,
    },

    item: {
        cursor: 'pointer'
    },

    uuid: {
        whiteSpace: "nowrap",
        fontSize: "80%",
        color: "#888888",
        textOverflow: "ellipsis"
    },

    uppercase: {
        textTransform: "uppercase"
    }
}));

export default CustomerGridComponent;