import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px;
  font-size: 14px;
  width: 100%;
  border-radius: 5px;
`;

export const Danger = styled(Container)`
  color: rgba(255,0,0, 1);
  border: 1px solid rgba(255,0,0, 1);
  background-color: rgba(255,0,0, 0.2);
`;

export const Success = styled(Container)`
  color: rgba(48,84,68, 1);
  border: 1px solid rgba(48,84,68, 1);
  background-color: rgba(80,250,123, 0.2);
`;

export const Warning = styled(Container)`
  color: rgba(255,69,0, 1);
  border: 1px solid rgba(255,165,0, 1);
  background-color: rgba(255,165,0, 0.2);
`;

export const Info = styled(Container)`
  color: rgba(123,104,238, 1);
  border: 1px solid rgba(123,104,238, 1);
  background-color: rgba(123,104,238, 0.2);
`;
