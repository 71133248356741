import {
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiButton from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { COOKIE_USER, TOKEN_KEY } from "../../configs/constants";
import { getToken } from "../../services/auth";
import { updatePasswordService, passwordValidation } from "./service";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

export const PasswordChangeModal = ({
  passwordChangeModalOpen,
  setPasswordChangeModalOpen,
}) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [validForm, setValidForm] = useState(false);
  const [cookies] = useCookies([COOKIE_USER, TOKEN_KEY]);

  const handleUpdatePassword = () => {
    const { email, role_id, id: userId, name } = cookies[COOKIE_USER];
    const token = getToken();

    setLoading(true);

    updatePasswordService({
      token,
      confirmPassword,
      email,
      password,
      role_id,
      userId,
      name,
    })
      .then((data) => {
        setSuccess(true);
        setTimeout(() => setSuccess(false), 4000);
        setPassword("");
        setConfirmPassword("");
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setTimeout(() => setError(false), 4000);
        setPassword("");
        setConfirmPassword("");
        setLoading(false);
      });
  };

  useEffect(() => {
    setValidForm(passwordValidation(password, confirmPassword));
  }, [confirmPassword, password]);

  return (
    <Dialog
      open={passwordChangeModalOpen}
      onClose={() => setPasswordChangeModalOpen(false)}
    >
      <DialogTitle>Troca de Senha</DialogTitle>
      <Divider></Divider>
      <DialogContent>
        {error && (
          <Alert variant="filled" severity="error">
            Erro
          </Alert>
        )}
        {success && (
          <Alert variant="filled" severity="success">
            Senha trocada com Sucesso !
          </Alert>
        )}
        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          type={passwordVisibility ? "text" : "password"}
          label="Digite sua Nova Senha"
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          disabled={loading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setPasswordVisibility(!passwordVisibility)}
                >
                  {passwordVisibility ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        ></TextField>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          type={confirmPasswordVisibility ? "text" : "password"}
          label="Confirme sua Nova Senha"
          value={confirmPassword}
          onChange={({ target }) => setConfirmPassword(target.value)}
          disabled={loading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() =>
                    setConfirmPasswordVisibility(!confirmPasswordVisibility)
                  }
                >
                  {confirmPasswordVisibility ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        ></TextField>
      </DialogContent>
      <Divider></Divider>
      <DialogActions>
        <CancelButton
          onClick={() => setPasswordChangeModalOpen(false)}
          disabled={loading}
        >
          Cancelar
        </CancelButton>
        <SuccessButton
          disabled={!validForm || loading}
          onClick={handleUpdatePassword}
        >
          Confirmar
        </SuccessButton>
      </DialogActions>
      {loading && <LinearProgress color="secondary"></LinearProgress>}
    </Dialog>
  );
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: "400px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "180px",
    borderLeft: "10px solid #43525a",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

const CancelButton = withStyles((theme) => ({
  root: {
    border: "1px solid #e57373",
    color: "#e57373",
    "&:hover": {
      color: "white",
      backgroundColor: "#e57373",
    },
  },
}))(MuiButton);

const SuccessButton = withStyles((theme) => ({
  root: {
    border: "1px solid #81c784",
    color: "#81c784",
    "&:hover": {
      backgroundColor: "#81c784",
      color: "white",
    },
  },
}))(MuiButton);
