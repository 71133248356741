import React, { useEffect, useState } from 'react';
import IconMedia from "@material-ui/icons/PermMedia"
import { makeStyles } from "@material-ui/core";
import AudioComponent from "./Audio";
import ScreenCaptureComponent from "./ScreenCapture";
import UploadComponent from "./Upload";
import ClipboardImageComponent from "./ClipboardImage";

const ChatMediaInput = ({ called }) => {

    const [active, setActive] = useState(false);
    const classes = useStyles();

    const closeMenu = () => {
        setActive(false);
    };

    return (
        <>
            <div>
                <div onMouseLeave={() => setActive(false)}  className={active? classes.mediaMenu : classes.mediaMenuInactive}>
                    <div className={classes.mediaMenuContainer}>
                        <div className={classes.mediaItem}>
                            <AudioComponent called={called.id} onClick={closeMenu} />
                        </div>
                        <div className={classes.mediaItem}>
                            <ScreenCaptureComponent called={called.id} onClick={closeMenu} />
                        </div>
                        <div className={classes.mediaItem}>
                            <UploadComponent called={called.id} onClick={closeMenu} />
                        </div>
                    </div>
                </div>
                <ClipboardImageComponent called={called.id} onClick={closeMenu} />
                <button onClick={() => setActive(!active)} className={classes.contentCenterFooterLeftMedia}>
                    <IconMedia style={{ color: "#FFFFFF", marginRight: 7 }} />
                    MÍDIA
                </button>
            </div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: 500,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },

    button: {
        marginTop: 15,
    },

    contentCenterFooterLeftMedia: {
        display: 'flex',
        alignItems: 'center'
    },

    mediaContainer: {
        minHeight: '100px',
    },

    clearfix: {
        clear: 'both',
        overflow: 'auto',
    },

    mediaMenu: {
        width: 280,
        height: 100,
        position: 'absolute',
        marginTop: -110,
        marginLeft: -20,
        backgroundColor: '#43525A'
    },

    mediaMenuInactive: {
        display: 'none',
    },

    mediaMenuContainer: {
        padding: '10px'
    },

    mediaItem: {
        textAlign: 'center',
        width: '30%px',
        float: 'left',
        cursor: 'pointer',
        margin: '0px 10px 20px 10px'
    },

}));

export default ChatMediaInput;