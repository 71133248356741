import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';

const ChatContentLog = ({ message }) => {

  const classes = useStyles();

  const [text, setText] = useState(null)

  useEffect(() => {
    const init = async () => {
      setText(message)
    }
    init();
  }, [message]);

  return (
    <div className={classes.contentChatLog}>
      <p className={classes.contentChatLogP}>
        {text && new Intl.DateTimeFormat("pt-BR", {
          year: "numeric",
          month: "long",
          day: "2-digit",
          hour: 'numeric', minute: 'numeric', second: 'numeric', 
        }).format(new Date(text.created_at))} <br /> {text && text.message}
      </p>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  contentChatLog: {
    width: '100%',
    textAlign: 'center',
    clear: 'both',
    marginTop: '15px',
    marginBottom: '15px',
  },
  contentChatLogP: {
    fontSize: 14,
    color: '#008265',
  }
}))

export default ChatContentLog
