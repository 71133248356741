import React, { useState, useRef, useEffect } from 'react';
import IconChat from "@material-ui/icons/ChatSharp";
import IconClose from '@material-ui/icons/CloseRounded';
import { FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, makeStyles } from "@material-ui/core";
import { Input, FormControl } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import CustomerGridComponent from './CustomerGrid';
import CustomerInfo from './CustomerInfo';
import api from '../../services/api';
import { Alert, Autocomplete } from '@material-ui/lab';
import CustomerForm from './CustomerForm';

const StartChat = () => {
    const [active, setActive] = useState(false);
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [modal, setModal] = useState(false);
    const [customer, setCustomer] = useState(null);
    const messageRef = useRef(null);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [department, setDepartment] = useState('');
    const [messages, setMessages] = useState([]);
    const [mensagemSelecionada, setMensagemSelecionada] = useState(null);
    const [tipoMensagem, setTipoMensagem] = useState('1');

    useEffect(() => {
        let effectSubscribed = true;
        if(messageRef && messageRef.current) messageRef.current.focus();
        const loadDepartments = async () => {
            if(effectSubscribed) {
                try {
                    const result = await api.get(`admin/departments/my`);
                    setDepartments(result.data.data);
                    if(result.data.data.length === 1) {
                        const dep = result.data.data[0];
                        setDepartment(dep.id);
                    }

                    const messageResults = await api.get(`admin/messages?per_page=1000`);
                    let items = [];
                    messageResults.data.data.data.map((item) => {
                        items.push({
                            label: item.name,
                            id: item.id
                        });
                    });
                    setMessages(items);
                } catch(err) {
                    console.log(err);
                }
            }
        };
        
        if(departments.length === 0) loadDepartments();
        return () => {
            effectSubscribed = false;
        }
    }, [customer]);

    const openModal = () => {
        setModal(true);
    };

    const cancel = () => {
        setCustomer(null);
        setModal(false);
    }

    const start = async () => {
        try {
            setErrorMessage(null);
            setError(false);
            setLoading(true);
            const data = {
                department: department,
                customer: {
                    uuid: customer.uuid,
                    name: customer.name,
                    origin: customer.origin,
                    email: customer.email,
                    system_id: customer.system_id
                },
                message
            };

            const response = await api.post('chat/start/operator', data);
            cancel();
        } catch(err) {
            if(err.request) {
                const error = JSON.parse(err.request.response);
                setErrorMessage(error.message);
            } else {
                setErrorMessage('Ocorreu um erro ao tentar iniciar o chat. Por favor, tente novamente.');
            }
            
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2 className={classes.headerTitle}>
                <IconChat />&nbsp;Iniciar Chat
            </h2>

            { modal && (
                <div>

                    {!customer && <CustomerGridComponent setCustomer={setCustomer} /> }
                    
                    { customer && (
                        <div>
                            <CustomerInfo customer={customer} /> 
                            <Grid container>
                                <Grid item sm={4}>
                                    <FormControl style={{marginTop: '20px'}} fullWidth={true}>
                                        <InputLabel id="demo-simple-select-label">
                                            Departamento
                                        </InputLabel>
                                        <Select
                                            id="depto-select"
                                            disabled={departments.length === 0}
                                            onChange={(e) => setDepartment(e.target.value)}
                                            value={department}
                                        >
                                            {departments.map((item) => (
                                            <MenuItem key={item.id.toString()} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={8}>
                                    <FormControl style={{marginTop: '20px', marginLeft: '20px'}} >
                                        <RadioGroup row aria-labelledby="tipo-msg-group-label" name="row-radio-buttons-group" 
                                            onChange={(e) => {
                                                setMensagemSelecionada(null);
                                                setMessage(null);
                                                setTipoMensagem(e.target.value)
                                            }}
                                            value={tipoMensagem}>
                                            <FormControlLabel value="1" control={<Radio />} label="Mensagem de template" />
                                            <FormControlLabel value="2" control={<Radio />} label="Digitar mensagem personalizada" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {(tipoMensagem === '2') && (
                                <FormControl style={{marginTop: '20px'}} fullWidth={true}>
                                    <InputLabel>
                                        Digite sua mensagem
                                    </InputLabel>
                                    <Input
                                        disabled={loading}
                                        inputRef={messageRef}
                                        fullWidth={true}
                                        maxRows={8}
                                        multiline={true}
                                        onChange={(evt) => {
                                            setMessage(evt.target.value);
                                        }}
                                    ></Input>
                                </FormControl>
                            )}

                            {tipoMensagem === '1' && messages.length > 0 && (
                                <FormControl style={{marginTop: '20px'}} fullWidth={true}>
                                    <Autocomplete 
                                        id="tipo-msg-select"
                                        value={mensagemSelecionada}
                                        options={messages}
                                        renderInput={(params) => <TextField {...params} label="Mensagem" />}
                                        getOptionLabel={(option) => option.label ?? ''}
                                        onChange={(evt, nv) => {
                                            setMessage(nv?.label);
                                            setMensagemSelecionada(nv);
                                        }}
                                    />
                                </FormControl>
                            )}
                        </div>
                    )}

                    { error && <Alert className={classes.alert} severity='error'>{errorMessage}</Alert> }
                    
                    <div className={classes.buttonsBottom}>
                        <Button
                            className={classes.button}
                            onClick={cancel}
                            variant="contained"
                            color="default"
                            >
                                <IconClose style={{ marginRight: 7 }} />
                                Cancelar
                        </Button>

                        { !customer && <CustomerForm setCustomer={setCustomer} />}

                        { customer && <Button
                            className={classes.button}
                            disabled={!message || !department || loading}
                            onClick={start}
                            variant="contained"
                            color="primary"
                            >
                                <IconChat style={{ marginRight: 7 }} />
                                Iniciar Conversa
                        </Button> 
                        }
                    </div>
                </div>
            )}
        </div>
    );

    function getModalStyle() {
        const top = 50;
        const left = 50;
    
        return {
          top: `${top}%`,
          left: `${left}%`,
          transform: `translate(-${top}%, -${left}%)`,
        };
    }

    return (
        <>
            <Modal
                open={modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
            
            <a href="#" onClick={() => openModal()}>
                <div 
                    onMouseEnter={() => setActive(true)} 
                    onMouseLeave={() => setActive(false)}                
                    className={active ? classes.startChatIconActive : classes.startChatIcon}>
                    <IconChat />
                </div>
            </a>
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: 900,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },

    startChatIcon: {
        position: 'fixed',
        width: "50px",
        height: "50px",
        bottom: "10px",
        left: "290px",
        backgroundColor: "#4ed898",
        color: "#fff",
        zIndex: 999,
        borderRadius: "50%",
        display: "flex",
        alignItems: 'center',
        justifyItems: 'center',
        justifyContent: 'center',
        cursor: "pointer",
        transition: "all 0.3s ease-in",
    },

    startChatIconActive: {
        position: 'fixed',
        width: "50px",
        height: "50px",
        bottom: "10px",
        left: "290px",
        backgroundColor: "#0b8460",
        color: "#fff",
        zIndex: 999,
        borderRadius: "50%",
        display: "flex",
        alignItems: 'center',
        justifyItems: 'center',
        justifyContent: 'center',
        cursor: "pointer",
        transition: "all 0.3s ease-in",
    },

    headerTitle: {
        alignItems: 'center',
        display: 'flex'
    },

    buttonsBottom: {
        marginTop: '30px',
        display: 'flex',
        justifyContent: 'space-between'
    },

    alert: {
        margin: '8px 0'
    }
}));

export default StartChat;