import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { Button, Modal, makeStyles, Grid, Input, FormControl, InputLabel } from '@material-ui/core';
import IconClose from '@material-ui/icons/CloseRounded';
import IconSave from '@material-ui/icons/Done';
import IconUser from '@material-ui/icons/PersonAdd';
import { Alert } from '@material-ui/lab';

const ChatEditClient = ({item}) => {
    const [modal, setModal] = useState(false);
    const [modalStyle] = useState(getModalStyle);
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        setName(item.conversation.client.name);
    }, [])

    const openModal = () => {
        setModal(true);
    }

    const cancel = () => {
        setModal(false);
    }

    const handleSubmit = (evt) => {
        if(evt && evt.key === 'Enter') {
            save();
        }
    }

    const save = async () => {
        setLoading(true);
        setError(false);
        try {
            const params = { 
                conversation_department_id: item.id,
                uuid: item.conversation.client.uuid,
                name 
            };
            const { data } = await api.put(`/admin/clients/${item.conversation.client.id}`, params);
            if(data.status) {
                cancel();
            } else {
                setError(true);
            }
        } catch(err) {
            setError(true);
        } finally {
            setLoading(false);
        }
        
    }

    function getModalStyle() {
        const top = 50;
        const left = 50;
    
        return {
          top: `${top}%`,
          left: `${left}%`,
          transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2 className={classes.headerTitle}>
                <IconUser />&nbsp;Editar Contato
            </h2>

            { modal && (
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl className={classes.input}>
                                <InputLabel>Nome completo<span className={classes.required}>*</span></InputLabel>
                                <Input
                                    readOnly={loading}
                                    placeholder=''
                                    label="Cliente"
                                    value={name}
                                    onKeyPress={handleSubmit}
                                    onChange={(e) => { setName(e.target.value)}}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    { error && <Alert severity='error'>Não foi possível atualizar os dados do cliente.</Alert> }
                    

                    <div className={classes.buttonsBottom}>
                        <Button
                            className={classes.button}
                            onClick={cancel}
                            disabled={loading}
                            variant="contained"
                            color="default"
                            >
                                <IconClose style={{ marginRight: 7 }} />
                                Cancelar
                        </Button>

                        <Button
                            disabled={!name || loading || name === item.conversation.client.name}
                            className={classes.button}
                            onClick={save}
                            variant="contained"
                            color="primary"
                            >
                                <IconSave style={{ marginRight: 7 }} />
                                Salvar
                        </Button>
                    </div>
                </div>
            )}

        </div>
    );

    return (
        <>
            <Modal
                open={modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
            &nbsp;&nbsp;<span className={classes.clientEdit}>|</span>&nbsp;&nbsp;
            <a href="#" className={classes.clientEditLink} onClick={openModal}>editar</a>
        </>
    )
};

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },

    buttonsBottom: {
        marginTop: '30px',
        display: 'flex',
        justifyContent: 'space-between'
    },

    headerTitle: {
        alignItems: 'center',
        display: 'flex'
    },

    input: {
        margin: theme.spacing(1),
        marginBottom: "20px",
        width: "100%",
    },

    required: {
        color: "#ff0000"
    },

    clientEdit: {
        fontSize: "11px !important",
    },

    clientEditLink: {
        fontSize: "11px !important",
        color: "#ff0000"
    }
}));

export default ChatEditClient;