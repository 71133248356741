import React, { useEffect, useState } from "react";
import sha1 from 'crypto-js/sha1';
import { ADMIN_HOST } from '../../../configs/constants';
import { makeStyles } from "@material-ui/core";
import icon from "../../../assets/images/video.png";

const MediaVideo = ({customStyles, text}) => {

    const classes = useStyles();
    const [link, setLink] = useState(null);


    useEffect(() => {
        if(text.media.includes('embed:')) {
            const t = text.media.split(';');
            setLink(t[1]);
        } else {
            const hash = sha1(text.media);
            setLink(`${ADMIN_HOST}conversations/media/${text.id}/${hash}`);
        }
        
    }, [text]);

    const open = (link) => {
        window.open(link, '_blank', 'width=800,height=600,toolbar=yes,menubar=no,menu=no');
    };


    return (
        <>
            <div className={customStyles.contentChatUserMedia || customStyles.contentChatOpMedia}>
                <span
                    className={classes.contentChatMediaThumb}
                    onClick={() => open(link)}
                >
                    <img className={classes.link} src={icon} height="100%" alt="thumb" />
                </span>

                <div className={classes.contentChatMediaInfo}>
                    <p className={classes.link}>Clique para visualizar</p>
                </div>
            </div>
        </>
    )
};

const useStyles = makeStyles((theme) => ({
    link: {
        cursor: 'pointer',
    },
    
    contentChatMediaThumb: {
        width: "auto",
        maxWidth: "calc(100% - 110px)",
        height: 145,
        border: "1px solid #D0D5D9",
        background: "#fff",
        display: "inline-block",
        float: "left",
        padding: 5,
    },
    contentChatMediaInfo: {
        width: 110,
        height: 145,
        color: "#677C88",
        fontSize: 12,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
    },
  }));

export default MediaVideo;