import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import { isAuthenticated } from "./services/auth";

import Login from './pages/login';
import ForgotPassword from './pages/forgot-password';
import ForgotChangePassword from './pages/forgot-change-password';
import Home from './pages/home';
import AuthLoading from './pages/auth-loading';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
    }
  />
);

const App = () => {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#43525A',
      },
      secondary: {
        main: '#008265'
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={AuthLoading} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/forgot-change-password" component={ForgotChangePassword} />
          <PrivateRoute path="/system" component={Home} />
          <Route path="*" component={() => <h1>Page not found</h1>} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  )

};

export default App;