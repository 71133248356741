import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  IconButton,
  MenuItem,
  Popover,
} from "@material-ui/core";
import { faUser, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getToken, logout } from "../../services/auth";

import QueueComponent from "../../components/Queue";
import CalledComponent from "../../components/Called";
import FastMessage from "../../components/FastMessage";
import Chat from "../../components/Chat";
import ChatClientInfo from "../../components/ChatClientInfo";
import ChatTransfer from "../../components/ChatTransfer";

import { queueCreate, queueUpdate, queueInit } from "../../store/actions/queue";
import {
  calledCreate,
  calledUpdate,
  calledUpdateItem,
  calledInit,
} from "../../store/actions/called";
import { sysCreate } from "../../store/actions/system";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";

import CheckIcon from "@material-ui/icons/Check";

import Clock from "react-live-clock";
import Socketio from "socket.io-client";
import Echo from "laravel-echo";
import logo from "../../assets/logo/topsapp03.png";

import Favicon from "react-favicon";

import Modal from "@material-ui/core/Modal";

import {
  COOKIE_USER,
  COOKIE_DEPARTMENT,
  LARAVEL_ECHO_HOST,
  DESKTOP_NOTIFICATION_ENABLED
} from "../../configs/constants";
import api from "../../services/api";
import { PasswordChangeModal } from "../../components/PasswordChangeModal";
import DesktopNotification from "../../components/DesktopNotification";
import StartChat from "../../components/StartChat";

// var contteste = 0;
// var called_new_message = 0;
// var called_channel = 0;

function openRealtime() {
  return new Echo({
    broadcaster: "socket.io",
    client: Socketio,
    host: LARAVEL_ECHO_HOST + ":2052",
    transports: ["websocket", "polling"],
    forceTLS: false,
    disableStats: true,
    auth: {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tc-operators"),
        Accept: "application/json",
      },
    },
  });
}

const Home = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const calledReducer = useSelector((state) => state.calledReducer);
  const [cookies, setCookie, removeCookie] = useCookies([
    COOKIE_USER,
    COOKIE_DEPARTMENT,
  ]);
  const [realtime, setRealtime] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [alterDepartmentModal, setAlterDepartmentModal] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userCalleds, setUserCalleds] = useState([]);
  const [departmentChannel, setDepartmentChannel] = useState(null);
  const [userChannel, setUserChannel] = useState(null);
  const [hasNewMessages, setHasNewMessage] = useState(0);
  const [passwordChangeModalOpen, setPasswordChangeModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [desktopMessage, setDesktopMessage] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    document.title = `Operador`;
  }, []);

  useEffect(() => setRealtime(openRealtime()), []);

  useEffect(() => {
    const init = async () => {
      cookies[COOKIE_USER] && listDepartments();
      realtime &&
        cookies[COOKIE_DEPARTMENT] &&
        (await enterDepartmentChannel());
      if (!cookies[COOKIE_USER] || !cookies[COOKIE_USER].name || !getToken()) {
        handleLogout();
      }
    };
    init();
  }, [cookies]);

  useEffect(() => {
    const init = async () => {
      if (realtime && cookies[COOKIE_USER] && cookies[COOKIE_DEPARTMENT]) {
        await enterDepartmentChannel();
        await enterUserChannel();
      }
    };
    init();
  }, [realtime]);

  const enterDepartmentChannel = async () => {
    dispatch(queueInit());
    setDepartmentChannel(
      await realtime.private("department." + cookies[COOKIE_DEPARTMENT].id)
    );
  };

  const enterUserChannel = async () => {
    dispatch(calledInit());
    setUserChannel(await realtime.private("user." + cookies[COOKIE_USER].id));
  };

  const listenDesktopMessages = async () => {
    departments.forEach(async (dep) => {
      await realtime.private("department." + dep.id).listen(".department.desktop.notification", (e) => {
        setDesktopMessage(e.message);
      });
    });
  };

  useEffect(() => {
    const init = () => {
      if(departments && departments.length > 0) {
        listenDesktopMessages();
      }
      departmentChannel &&
        departmentChannel
          .listen(".department.client.new", (e) => {
            dispatch(queueUpdate(e.conversationDepartment));
          })
          .listen(".department.queue.update", (e) => {
            dispatch(queueCreate(e.queue));
          });
    };
    init();
  }, [departmentChannel]);

  useEffect(() => {
    const init = () => {
      userChannel &&
        userChannel
          .listen(".user.called.new", (e) => {
            dispatch(calledUpdate(e.conversationDepartment));
            dispatch(sysCreate({ modal: false }));
          })
          .listen(".user.called.update", (e) => {
            if(e.reload) {
              dispatch(calledInit());
              dispatch(calledCreate(e.called));
            }
          });
    };
    init();
  }, [dispatch, userChannel]);

  const addCalled = useCallback(
    (called) => {
      if (userCalleds.indexOf(called.id) === -1) {
        setUserCalleds((userCalleds) => [...userCalleds, called.id]);
        realtime
          .private("called." + called.id)
          .listen(".called.message.new", (e) => {
            dispatch(calledUpdateItem(e.called));
          });
      }
    },
    [dispatch, realtime, userCalleds]
  );

  useEffect(() => {
    const init = async () => {
      if (calledReducer.called.length) {
        calledReducer.called.forEach((called) => {
          addCalled(called);
        });
      } else {
        userCalleds.forEach((calledId) =>
          realtime.leaveChannel("called." + calledId)
        );
        setUserCalleds([]);
      }
    };
    init();
  }, [calledReducer.called]);

  const listDepartments = () => {
    setDepartments(cookies[COOKIE_USER].departments);
  };

  async function handleLogout() {          
    history.push("/login");
    try {
      await api.post("/auth/logout", {});
    } catch(err) {
      console.log('Erro ao fazer logout na API');
    } finally {
      logout();
      dispatch(queueInit());
      dispatch(calledInit());
      removeCookie(COOKIE_DEPARTMENT);
      removeCookie(COOKIE_USER);
    }
  };

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  function generate(element) {
    return (
      departments &&
      departments.map((value) =>
        React.cloneElement(element, {
          key: value,
        })
      )
    );
  }

  const handleListItemClick = async (event, index) => {
    if (departments) {
      setAlterDepartmentModal(false);
      departments.forEach((value) => {
        if (index === value.id) {
          realtime.leave("department." + cookies[COOKIE_DEPARTMENT].id);
          removeCookie(COOKIE_DEPARTMENT);
          setCookie(COOKIE_DEPARTMENT, value);
        }
      });
      setSelectedIndex(index);
    }
  };

  const pingServer = () => {
    setInterval(() => {
      api.get("/ping").then(() => {});
    }, 36000);
  };

  useEffect(() => pingServer(), []);

  useEffect(() => {
    const callWithUnreadMessages = calledReducer.called.filter(
      (e) => e.hasNewMessages
    );
    if (callWithUnreadMessages.length > 0) {
      setHasNewMessage(callWithUnreadMessages.length);
    } else {
      setHasNewMessage(0);
    }
  }, [calledReducer]);

  return (
    <>
      <Favicon
        url={"assets/favicon/favicon.ico"}
        alertCount={hasNewMessages !== 0 ? hasNewMessages : null}
      ></Favicon>
     {DESKTOP_NOTIFICATION_ENABLED && (<DesktopNotification message={desktopMessage}/>) }
      <Modal
        open={alterDepartmentModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2>Meus setores</h2>
          <List dense={false} component="nav">
            {departments &&
              departments.map((value) => (
                <ListItem
                  key={value.id}
                  button
                  selected={cookies[COOKIE_DEPARTMENT].id === value.id}
                  onClick={(event) => handleListItemClick(event, value.id)}
                >
                  {cookies[COOKIE_DEPARTMENT].id === value.id && (
                    <ListItemIcon>
                      <CheckIcon />
                    </ListItemIcon>
                  )}
                  <ListItemText primary={value.name} />
                </ListItem>
              ))}
          </List>
          <Button
            className={classes.button}
            onClick={() => setAlterDepartmentModal(false)}
            variant="contained"
            color="primary"
          >
            Fechar
          </Button>
        </div>
      </Modal>
      <StartChat />
      <ChatTransfer />
      <PasswordChangeModal
        passwordChangeModalOpen={passwordChangeModalOpen}
        setPasswordChangeModalOpen={setPasswordChangeModalOpen}
      ></PasswordChangeModal>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <IconButton aria-describedby={id} onClick={handleClick}>
            <FontAwesomeIcon icon={faUser} color="white" />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem onClick={() => setPasswordChangeModalOpen(true)}>
              Troca de Senha
            </MenuItem>
          </Popover>
          <div id="headerUserName" className={classes.userName}>
            {cookies[COOKIE_USER] && cookies[COOKIE_USER].name}
            &nbsp;|
            <button onClick={() => handleLogout()} className={"transitionFX"}>
              Sair
            </button>
          </div>
        </div>
        <div className={classes.headerCenter}>
          <div className={classes.headerCenterTitle}>
            {cookies[COOKIE_DEPARTMENT] && cookies[COOKIE_DEPARTMENT].name}
            {departments && departments.length > 1 && (
              <>
                <FontAwesomeIcon
                  onClick={() => setAlterDepartmentModal(true)}
                  className={classes.carretDown}
                  icon={faCaretDown}
                />
              </>
            )}
          </div>
        </div>
        <div className={classes.headerRight}>
          <div className={classes.headerRightAvailable}>&nbsp;</div>
          {/* <div className={classes.headerRightSeparator}>
            &nbsp;
          </div> */}
          <div className={classes.headerRightTime}>
            <div>
              <Clock
                format="DD/MM/YYYY"
                interval={1000}
                ticking={true}
                timezone={"AMERICA/CUIABA"}
              />
            </div>
            <div>
            <Clock format="HH:mm:ss" interval={1000} ticking={true} />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.contentLeft}>
          <CalledComponent />
          <Divider />
          <QueueComponent />
        </div>

        <div className={classes.contentCenter}>
          <div className={classes.contentCenterCard}>
            {calledReducer.windowActive !== false && <Chat />}
          </div>
        </div>
        <div className={classes.contentRight}>
          <div
            id="rightQuickMessages"
            className={classes.contentRightQuickMessages}
          >
            <FastMessage />
            <ChatClientInfo />
          </div>
          <div className={classes.contentRightLogo}>
            <img
              src={logo}
              width="auto"
              height="80%"
              max-width="80%"
              max-height="80px"
              alt="logotipo"
            />
          </div>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  carretDown: {
    marginLeft: 10,
  },
  bodyModalClosed: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  button: {
    marginTop: 15,
  },
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  header: {
    backgroundColor: "#43525A",
    height: 60,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
    color: "#FFFFFF",
  },
  headerLeft: {
    width: 350,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  headerCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  headerCenterTitle: {
    fontSize: 22,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  headerRight: {
    width: 350,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerRightAvailable: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  headerRightAvailableText: {
    marginRight: 10,
    fontSize: 15,
  },
  headerRightSeparator: {
    backgroundColor: "#677C88",
    width: 2,
    height: 42,
    marginLeft: 15,
    marginRight: 15,
  },
  headerRightTime: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: 15,
  },
  userName: {
    marginLeft: 10,
    fontSize: "1.07rem",
    height: "25px",
    fontWeight: 500,
  },
  content: {
    backgroundColor: "#F5F5F5",
    height: "calc(100vh - 60px)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  contentLeft: {
    backgroundColor: "#FFFFFF",
    width: 300,
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  contentLeftAttending: {
    height: "50%",
  },
  contentCenter: {
    flex: 1,
    padding: "20px",
  },
  contentLeftWaiting: {
    paddingTop: 10,
  },
  contentLeftHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 10px",
    color: "#43525A",
    fontSize: 15,
    lineHeight: "1.2rem",
    fontWeight: "bold",
  },
  contentCenterCard: {
    backgroundColor: "#FFFFFF",
    borderColor: "#D0D5D9",
    borderWidth: 1,
    borderStyle: "solid",
  },
  contentRight: {
    width: 300,
  },
  contentRightQuickMessages: {
    backgroundColor: "#FFFFFF",
    height: "calc(100vh - 205px)",
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    overflowX: "auto",
  },
  contentRightLogo: {
    display: "flex",
    width: "100%",
    height: 80,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 25,
    marginBottom: 25,
    cursor: "pointer",
  },
}));

export default Home;
