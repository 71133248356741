import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { calledInputTextAdd } from "../../store/actions/called";

import ChatHeader from "../ChatHeader";
import ChatContent from "../ChatContent";
import ChatFooter from "../ChatFooter";

const Chat = () => {
  const calledReducer = useSelector((state) => state.calledReducer);
  const dispatch = useDispatch();
  const [item, setItem] = useState(null);

  useEffect(() => {
    const init = async () => {
      dispatch(calledInputTextAdd(""));
      if (calledReducer.windowActive !== false) {
        setItem(calledReducer.called[calledReducer.windowActive]);
      } else {
        setItem(null);
      }
    };
    init();
  }, [calledReducer.called, calledReducer.windowActive, dispatch]);

  useEffect(() => {
    const init = async () => {
      setItem(calledReducer.called[calledReducer.windowActive]);
    };
    init();
  }, [calledReducer.called, calledReducer.windowActive]);

  return (
    <>
      {item !== null ? (
        <>
          <ChatHeader item={item} />
          <ChatContent item={item} />
          <ChatFooter item={item} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Chat;
